import React, { useRef, useMemo } from 'react';
import { Picker } from 'antd-mobile';

import styles from './index.module.less';

const Pick = (props) => {
  const { data } = props;
  const pickerRef = useRef();
  const pickColums = useMemo(() => {
    return data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      }
    });
  }, [data]);

  return (
    <>
      <Picker
        columns={[pickColums]}
        onConfirm={(value) => {
          const [platformId] = value;
          props.handleConfirm(platformId);
        }}
        ref={pickerRef}
      >
        {(items) => {
          return (
            <div
            className={styles.pickGroupBox}
            onClick={() => {
              pickerRef.current?.open();
            }}>
              {items.every((item) => item === null) ? (
                <div>
                  {'请选择数字藏品平台(可选)'}
                </div>
              ) : (
                items.map((item) => item?.label ?? '').join(' - ')
              )}
            </div>
          );
        }}
      </Picker>
    </>
  )
}

export default Pick;
