import CryptoJS from "crypto-js";

export function progressiveRead(file, work, done) {
  const chunkSize = 204800;
  let pos = 0;
  const reader = new FileReader();
  function progressiveReadNext() {
    const end = Math.min(pos + chunkSize, file.size);
    reader.onload = function (e) {
      pos = end;
      work(e.target.result, pos, file);
      if (pos < file.size) {
        setTimeout(progressiveReadNext, 0);
      } else {
        done(file);
      }
    };
    let blob = null;
    if (file.slice) {
      blob = file.slice(pos, end);
    } else if (file.webkitSlice) {
      blob = file.webkitSlice(pos, end);
    }
    reader.readAsArrayBuffer(blob);
  }
  setTimeout(progressiveReadNext, 0);
}

function trans32(val) {
  return (
    (((val & 0xff) << 24) |
      ((val & 0xff00) << 8) |
      ((val >> 8) & 0xff00) |
      ((val >> 24) & 0xff)) >>>
    0
  );
}

export function arrayBufferToWordArray(arrayBuffer) {
  const fullWords = Math.floor(arrayBuffer.byteLength / 4);
  const bytesLeft = arrayBuffer.byteLength % 4;
  const u32 = new Uint32Array(arrayBuffer, 0, fullWords);
  const u8 = new Uint8Array(arrayBuffer);
  const words = [];
  for (let i = 0; i < fullWords; ++i) {
    words.push(trans32(u32[i]));
  }
  if (bytesLeft) {
    let pad = 0;
    for (let i = bytesLeft; i > 0; --i) {
      pad = pad << 8;
      pad += u8[u8.byteLength - i];
    }
    for (let i = 0; i < 4 - bytesLeft; ++i) {
      pad = pad << 8;
    }
    words.push(pad);
  }
  return CryptoJS.lib.WordArray.create(words, arrayBuffer.byteLength);
}

export function isMobile() {
  return window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
}
