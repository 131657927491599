import React, { useRef, useEffect } from "react";

export default function Video({ src, isMuted }) {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    if (isMuted) {
      //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }

    refVideo.current.src = src;
  }, [src, isMuted]);

  return (
    <video
      ref={refVideo}
      autoPlay
      loop
      playsInline //FIX iOS black screen
    />
  );
}
