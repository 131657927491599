import { Button } from "antd";
import classNames from "classnames";
import styles from "./index.module.less";

export default function Index({ onLoginClick }) {
  const content = [{
    title: '数据存证',
    content: [{
      title: '数据知识产权存证',
      info: '对个人数据、企业数据或公共数据进行哈希存证，不存储源文件，让数据更安全。是数据知识产权登记前的必要步骤。'
    }, {
      title: '文件存证',
      info: '以区块链为底层技术为用户提供文件存证确权服务。'
    }]
  }, {
    title: '版权存证',
    content: [{
      title: '版权预登记',
      info: '原创内容即时确权，可信溯源。'
    }, {
      title: '版权登记',
      info: '一站式版权登记代办，专业顾问服务。'
    }]
  }];

  return (
    <div className={styles.main}>
      {content.map(item =>
        <div className={styles.card} key={item.title}>
          <div className={classNames(styles.cardTitle, styles.title)}>{item.title}</div>
          <div className={styles.cardSec}>
            {item.content.map(content => (
              <div className={styles.cardBody}>
                <div className={styles.title}>{content.title}</div>
                <div className={styles.cardCon}>
                  {content.info}
                </div>
                <Button className={styles.button} onClick={onLoginClick}>立即试用</Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}