import React from "react";
import Footer from "../../components/NewComponents/Footer";
import Header from "../../components/NewComponents/Header";
import CertInspect from "../../components/NewComponents/CertInspect";
import SideButton from "../../components/NewComponents/SideButton";

export default function CertPage() {
  return (
    <>
      <Header selectedKeys={["cert"]} />
      <CertInspect />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </>
  );
}
