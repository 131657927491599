import React, { useState } from "react";
import styles from "./index.module.less";
import { ArrowRightOutlined, ArrowDownOutlined } from "@ant-design/icons";

const Index = (props) => {
  const [isShow, setIsShow] = useState(props.defaultShow);

  return (
    <>
      <div
        className={styles.infoCard}
        key={props.key}
        style={
          isShow
            ? { border: "1px solid rgba(51, 95, 255, 0.2)", width: props.width }
            : null
        }
      >
        <div className={styles.infoCardTop} onClick={() => setIsShow(!isShow)}>
          <img className={styles.infoCardTopImg} src={props.logo} alt="" />
          <div className={styles.infoCardTopName}>{props.name}</div>
          <div className={styles.arrowContainer}>
            {isShow ? (
              <ArrowDownOutlined className={styles.arrowDownOutlined} />
            ) : (
              <ArrowRightOutlined className={styles.arrowRightOutlined} />
            )}
          </div>
        </div>
        {isShow && <div className={styles.infoCardBottom}>{props.content}</div>}
      </div>
    </>
  );
};

export default Index;
