import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.less";
import contactIcon from "../../../assets/newImg/homePageImg/contactIcon.png";

const Index = (props) => {
  const { icon, label, url } = props;

  return (
    <>
      <Link to={{ pathname: url }}>
        <div className={styles.button}>
          <img className={styles.icon} src={icon ? icon : contactIcon} alt="" />
          <div className={styles.label}>{label}</div>
        </div>
      </Link>
    </>
  );
};

export default Index;
