import React, { useState, useEffect } from "react";
import { useRequest } from "@umijs/hooks";
import { ConfigProvider, Pagination } from "antd";
import topImgMobile from "../../../assets/newImg/newsPageImg/newsPhone@2x.png";
import NewsCard from "./components/NewsCard";
import PhoneNewsCard from "./components/PhoneNewsCard";
import styles from "./index.module.less";
import zhCN from "antd/lib/locale/zh_CN";
import axios from "axios";

export default function Index() {
  const [dataNews, setDataNews] = useState({});
  const pageSize = 5;
  function requestNews(params) {
    return axios.get(
      `/v1/info?pageNo=${params.pageNo}&pageSize=${params.pageSize}`
    );
  }
  const { run: getNews } = useRequest(requestNews, {
    paginated: true,
    manual: true,
  });
  const refreshData = async (pageNumber) => {
    const result = await getNews({ pageNo: pageNumber, pageSize });
    setDataNews(result?.data);
    window.scrollTo(0, 0); // 切换资讯页面时，回到页面顶部
  };
  useEffect(() => {
    refreshData(1);
  }, []);

  const bodyNews = (
    <div className={styles.container}>
      <div className={styles.bodyNews}>
        <div className={styles.bodyNewsTitle}>资讯动态</div>
        <div className={styles.newsBox}>
          {dataNews?.data?.list?.map((item) => {
            return (
              <NewsCard
                url={item.url}
                key={item.id}
                cover={item.cover}
                mainTitle={item.mainTitle}
                subtitle={item.subtitle}
                createTime={item.createTime}
              />
            );
          })}
        </div>
        <center className={styles.pageMove}>
          <ConfigProvider locale={zhCN}>
            <>
              <Pagination
                defaultCurrent={1}
                current={dataNews?.data?.currentPage}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                total={dataNews?.data?.totalCount}
                showQuickJumper={true}
                onChange={(e) => {
                  refreshData(e);
                }}
              />
              <br />
            </>
          </ConfigProvider>
        </center>
      </div>
    </div>
  );
  const phoneNews = (
    <div className={styles.phoneNews}>
      <div className={styles.phoneNewsTitle}>资讯动态</div>
      <div className={styles.phoneNewsBox}>
        {dataNews?.data?.list?.map((item) => {
          return (
            <PhoneNewsCard
              url={item.url}
              key={item.id}
              cover={item.cover}
              mainTitle={item.mainTitle}
              createTime={item.createTime}
            />
          );
        })}
      </div>
      <div className={styles.phoneNewsPageMove}>
        <ConfigProvider locale={zhCN}>
          <>
            <Pagination
              defaultCurrent={1}
              current={dataNews?.data?.currentPage}
              defaultPageSize={pageSize}
              pageSize={pageSize}
              total={dataNews?.data?.totalCount}
              showQuickJumper={true}
              onChange={(e) => {
                refreshData(e);
              }}
            />
            <br />
          </>
        </ConfigProvider>
      </div>
    </div>
  );
  return (
    <>
      <div className={styles.pcNews}>
        <div className={styles.topBg}>
        </div>
        {bodyNews}
      </div>
      <div className={styles.phoneNews}>
        <div className={styles.phoneTopBg}>
          <img
            className={styles.phoneTopImg}
            src={topImgMobile}
            alt="topImg"
            width={window.innerWidth}
          />
        </div>
        {phoneNews}
      </div>
    </>
  );
};
