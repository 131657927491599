import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Descriptions, message, Image } from "antd";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import Video from "../components/Video";
import animation from "../assets/animation.mp4";
import axios from "axios";
import Header from "../components/NewComponents/Header";
import Footer from "../components/NewComponents/Footer";

export default function Check() {
  const [show, setShow] = useState(2);
  const [checkRes, setCheckRes] = useState({ userPhone: "" });
  const [checkResContent, setCheckResContent] = useState({ userPhone: "" });
  const inputRef = useRef(null);
  const location = useLocation();
  const strSearch = location.search;
  let firstSixHash;
  firstSixHash = strSearch.split("=")[1];
  useEffect(() => {
    if (typeof firstSixHash !== "undefined") {
      checkHash(firstSixHash);
    }
  }, [firstSixHash]);

  if (window.innerWidth > 500) {
    window.location.href = "/";
    return <></>;
  }

  const checkHash = async (hash) => {
    if (!hash) {
      message.error("请输入证据哈希");
      return false;
    }
    try {
      const { data } = await axios.get(
        `/v1/verify/cert?firstSixHash=${hash.trim()}`
      );
      if (data.code !== 200) {
        throw new Error(data.message);
      }

      setShow(data.data.type);
      setCheckRes(data.data);
      if (data.data.type === 3) {
        const contentObj = JSON.parse(data.data.content);
        setCheckResContent(contentObj);
      }
    } catch (e) {
      message.warning(e.message);
    }
  };

  const onCertBack = () => {
    setShow(2);
  };

  return (
    <>
      <Header />
      <div
        className={`${styles.mobile} ${show !== 2 ? styles.bgc : null}`}
        style={
          show !== 1
            ? show === 3
              ? {}
              : { height: "100%" }
            : { height: "fit-content" }
        }
      >
        {show === 2 && (
          <>
            <h3>证据查询</h3>
            <Input
              placeholder="请输入证据哈希"
              ref={inputRef}
              defaultValue={firstSixHash}
            />
            <Button
              type="primary"
              onClick={() => checkHash(inputRef.current.state.value)}
            >
              开始校验
            </Button>
            {/* <Img name="mobile_logo" /> */}
          </>
        )}
        {show === 1 && (
          <>
            <Video src={animation} isMuted={true}></Video>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={styles.backLink} onClick={onCertBack}>
              <ArrowLeftOutlined /> 继续查验
            </a>
            <div className="block">
              <div className="title">个人信息</div>
              {/* <Link to={{
              pathname: `/cert`,
              state: { src: checkRes.certUrl }
            }} className="btn-position">点击查看证书</Link> */}
              {/* <Button type="primary" className="btn-position" onClick={() => {window.open(checkRes.certUrl)}}>查看证书</Button> */}
              <Descriptions column={1}>
                <Descriptions.Item label="姓名">
                  {checkRes.userName}
                </Descriptions.Item>
                <Descriptions.Item label="手机号码">
                  {checkRes.userPhone.replace(
                    /^(\d{3})\d{4}(\d{4})$/,
                    "$1****$2"
                  )}
                </Descriptions.Item>
                {/* <Descriptions.Item label="工作单位">{checkRes.userOrg}</Descriptions.Item> */}
              </Descriptions>
            </div>
            <div className="block" style={{ margin: "28px 0" }}>
              <div className="title">区块信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="区块号">
                  {checkRes.blockNumber}
                </Descriptions.Item>
                <Descriptions.Item label="所属区块链">
                  {checkRes.chainName}
                </Descriptions.Item>
                <Descriptions.Item label="区块Hash">
                  {checkRes.blockHash}
                </Descriptions.Item>
                <Descriptions.Item label="交易哈希">
                  {checkRes.txHash}
                </Descriptions.Item>
                <Descriptions.Item label="证书Hash">
                  {checkRes.contentHash}
                </Descriptions.Item>
                <Descriptions.Item label="上链时间">
                  {checkRes.time}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="block">
              <div className="title">证书信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="证书名称">
                  {checkRes.certName}
                </Descriptions.Item>
                <Descriptions.Item label="活动名称">
                  {checkRes.activityName}
                </Descriptions.Item>
                <Descriptions.Item label="活动日期">
                  {checkRes.activityStartDate}
                </Descriptions.Item>
                <Descriptions.Item label="活动地点">
                  {checkRes.address}
                </Descriptions.Item>
                <Descriptions.Item label="主办方">
                  {checkRes.organizer}
                </Descriptions.Item>
                <Descriptions.Item label="发证方">
                  {checkRes.issuer}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </>
        )}
        {show === 0 && (
          <>
            <Video src={animation} isMuted={true}></Video>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={styles.backLink} onClick={onCertBack}>
              <ArrowLeftOutlined /> 继续查验
            </a>
            <div className="block" style={{ margin: "14px 0" }}>
              <div className="title">上链信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="区块高度">
                  {checkRes.blockNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Hash码">
                  {checkRes.txHash}
                </Descriptions.Item>
                <Descriptions.Item label="案例编号">
                  {checkRes.caseNumber}
                </Descriptions.Item>
                <Descriptions.Item label="案例名称">
                  {checkRes.caseName}
                </Descriptions.Item>
                <Descriptions.Item label="上链时间">
                  {checkRes.time}
                </Descriptions.Item>
                <Descriptions.Item label="关联单位">
                  {checkRes.relatedOrg}
                </Descriptions.Item>
              </Descriptions>
            </div>
            {checkRes.content ? (
              <Link
                to={{
                  pathname: `/certsummary`,
                  state: {
                    local: true,
                    content: checkRes.content,
                  },
                }}
                className="sum sum-mobile"
                style={{ marginTop: "575px" }}
              >
                点击查看案例概述
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/certsummary`,
                  state: {
                    local: true,
                    content: false,
                  },
                }}
                className="sum sum-mobile"
                style={{ marginTop: "575px" }}
              >
                点击查看案例概述
              </Link>
            )}
          </>
        )}
        {show === 3 && (
          <div className={styles.resultWithCertImg}>
            <Video src={animation} isMuted={true}></Video>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={styles.backLink} onClick={onCertBack}>
              <ArrowLeftOutlined /> 继续查验
            </a>
            <div className={styles.blockImg}>
              <div className={styles.titleImg}>取证信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="取证ID">
                  {checkResContent.requestId}
                </Descriptions.Item>
                <Descriptions.Item label="取证人">
                  {checkResContent.person}
                </Descriptions.Item>
                <Descriptions.Item label="取证时间">
                  {checkResContent.createTime}
                </Descriptions.Item>
                {checkResContent.way !== 0 ? (
                  <>
                    {/* <Descriptions.Item label="取证时长">{checkResContent.evidenceTime}秒</Descriptions.Item>
                      <Descriptions.Item label="地理位置">{checkResContent.location}</Descriptions.Item> */}
                  </>
                ) : (
                  <Descriptions.Item label="取证网址">
                    {checkResContent.url}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div className={styles.blockImg}>
              <div className={styles.titleImg}>保全信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="保全人">
                  {checkResContent.savePerson}
                </Descriptions.Item>
                <Descriptions.Item label="保全时间">
                  {checkResContent.saveTime}
                </Descriptions.Item>
                {checkResContent.orgName && (
                  <Descriptions.Item label="保全机构">
                    {checkResContent.orgName}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>

            <div className={styles.blockImg}>
              <div className={styles.titleImg}>区块信息</div>
              <Descriptions column={1}>
                <Descriptions.Item label="区块号">
                  {checkRes.blockNumber}
                </Descriptions.Item>
                <Descriptions.Item label="所属区块链">
                  {checkRes.chainName}
                </Descriptions.Item>
                <Descriptions.Item label="区块hash">
                  {checkRes.blockHash}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div className={styles.blockImg}>
              <div className={styles.titleImg}>保全证书</div>
              <Image src={checkRes.certUrl} className={styles.certImg} />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
