import React from "react";
import Footer from "../../components/NewComponents/Footer";
import Header from "../../components/NewComponents/Header";
import KxzfyBody from "./components/KxzfyBody";
import SideButton from "../../components/NewComponents/SideButton";

export default function Kxzfy() {
  return (
    <>
      <Header selectedKeys={["kxzfy"]} />
      <KxzfyBody />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </>
  );
}
