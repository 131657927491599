import { Button } from "antd";
import styles from "./index.module.less";

export default function Index({ onLoginClick }) {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.headerTitle} />
          <div className={styles.headerInfo}>
            基于全国性公信联盟区块链平台，为数据知识产权提供区块链存证及公证保全， 致力于数据资产的可信确权与价值流通。
          </div>
          <Button className={styles.headerButton} onClick={onLoginClick}>立即登录</Button>
        </div>
      </div>
    </>

  );
}