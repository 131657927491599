import React, { useState, useEffect } from "react";
import { Carousel } from "antd";
import { stringify } from 'qs';
import { ArrowRightOutlined } from "@ant-design/icons";
import { useRequest } from "@umijs/hooks";
import { Link } from "react-router-dom";
import styles from "./index.module.less";
import bannerImgDefault from "../../../assets/newImg/bannerImg/bannerImgDefault@2x.png";
import phoneBannerImgDefault from "../../../assets/newImg/bannerImg/phoneBannerImgDefault@2x.png";
// import immediateEviCollect from "../../../assets/newImg/bannerImg/bannerBtnImg/immediateEviCollect@2x.png";
import phoneImmediateEviCollect from "../../../assets/newImg/bannerImg/bannerBtnImg/phoneImmediateEviCollect@2x.png";
// import checkCert from "../../../assets/newImg/bannerImg/bannerBtnImg/checkCert@2x.png";
import phoneCheckCert from "../../../assets/newImg/bannerImg/bannerBtnImg/phoneCheckCert@2x.png";
// import bussinessCooperation from "../../../assets/newImg/bannerImg/bannerBtnImg/bussinessCooperation@2x.png";
import phoneBussinessCooperation from "../../../assets/newImg/bannerImg/bannerBtnImg/phoneBussinessCooperation@2x.png";
import axios from "axios";

const bannerImgs = [
  {
    num: 1,
    src: bannerImgDefault,
    srcH5: phoneBannerImgDefault,
    key: "bannerImgDefault",
  },
];

// 可以写在外边或者用hooks
const imgWidth = window.innerWidth + "px";
const imgHeight = (window.innerWidth * 350) / 375 + "px";

function requestNews(params) {
  return axios.get(`/v1/banner?${stringify(params)}`);
}

export default function Index() {
  const [newsList, setNewsList] = useState();
  const { run: getNews } = useRequest(()=> requestNews({ pageSize: 4, pageNo: 1 }), {
    manual: true,
  });
  const getNewsBanner = async () => {
    const result = await getNews();
    setNewsList(result?.data?.data?.list);
  };
  useEffect(() => {
    getNewsBanner();
  }, []);
  const newsBanner = (
    <div className={styles.newsBannerContainer}>
      <div className={styles.newsBanner}>
        <Carousel
          dots={true}
          dotPosition="bottom"
          autoplay={true}
          autoplaySpeed={3000}
        >
          {newsList?.map((item) => {
            return (
              <div key={item.createTime}>
                <div className={styles.bannerImg} style={{backgroundImage:`url(${item.banner})`}} onClick={() => window.open(item.url)} />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
  const defaultBanner = (
    <div className={styles.defaultBannerContainer}>
      <div className={styles.sectionBanner}>
        <Carousel
          dots={true}
          dotPosition="bottom"
          autoplay={true}
          autoplaySpeed={3000}
        >
          {bannerImgs.map((item) => {
            return (
              <img
                className={styles.bannerImg}
                src={item.src}
                key={item.key}
                alt={item.key}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
  const pcBanner = (
    <div className={styles.pcBanner}>
       {(typeof newsList === 'undefined' || newsList?.length === 0) ? defaultBanner : newsBanner}
      {/*{defaultBanner}*/}
      {/* <div className={styles.bannerButtons}>
        <div className={styles.sectionBannerButtons}>
          <Link to={{ pathname: "/app" }}>
            <div className={styles.btnCollect}>
              <img
                className={styles.btnLogo1}
                src={immediateEviCollect}
                alt=""
              />
              <div className={styles.btnContent1}>
                <div className={styles.btnTitle1}>即时取证</div>
                <div className={styles.btnSlogan1}>品牌保护，直达公证</div>
                <div className={styles.btnTips}>
                  <span className={styles.btnTipsTxt}>立即体验</span>
                  <span className={styles.btnTipsArrow}> →</span>
                </div>
              </div>
            </div>
          </Link>
          <Link to={{ pathname: "/cert" }}>
            <div className={styles.btnCert}>
              <img className={styles.btnLogo2} src={checkCert} alt="" />
              <div className={styles.btnContent2}>
                <div className={styles.btnTitle2}>证书查验</div>
                <div className={styles.btnSlogan2}>上链数据，随时查验</div>
                <div className={styles.btnTips}>
                  <span className={styles.btnTipsTxt}>马上核验</span>
                  <span className={styles.btnTipsArrow}> →</span>
                </div>
              </div>
            </div>
          </Link>
          <Link to={{ pathname: "/contact" }}>
            <div className={styles.btnBussiness}>
              <img
                className={styles.btnLogo3}
                src={bussinessCooperation}
                alt=""
              />
              <div className={styles.btnContent3}>
                <div className={styles.btnTitle3}>业务合作</div>
                <div className={styles.btnSlogan3}>链上链下，开放创新</div>
                <div className={styles.btnTips}>
                  <span className={styles.btnTipsTxt}>联系我们</span>
                  <span className={styles.btnTipsArrow}> →</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div> */}
    </div>
  );

  const phoneDefaultBanner = (
    <div className={styles.phoneDefaultBanner}>
      <div className={styles.phoneDefaultBannerContainer}>
        <img
          className={styles.phoneDefaultBannerImg}
          src={phoneBannerImgDefault}
          width={window.innerWidth}
          alt=""
        />
      </div>
    </div>
  );
  const phoneNewsBanner = (
    <div className={styles.phoneNewsBanner}>
      <div
        className={styles.phoneNewsBannerContainer}
        style={{ width: window.innerWidth, height: imgHeight }}
      >
        <Carousel
          dots={true}
          dotPosition="bottom"
          autoplay={true}
          autoplaySpeed={3000}
        >
          {newsList?.map((item) => {
            return (
              <img
                className={styles.phoneNewsBannerImg}
                src={item.bannerForH5}
                key={item.createTime}
                alt={item.title}
                onClick={() => window.open(item.url)}
                width={imgWidth}
                height={imgHeight}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
  const phoneBanner = (
    <div className={styles.phoneBanner}>
       {(typeof newsList === 'undefined' || newsList?.length === 0) ? phoneDefaultBanner : phoneNewsBanner}
      {/*{phoneDefaultBanner}*/}
      <div className={styles.phoneBannerBtns}>
        <div className={styles.phoneBannerBtnsTop}>
          <Link to={{ pathname: "/app" }}>
            <div
              className={styles.phoneBannerBtnsTopItem}
              style={{
                marginRight: "15px",
                width: `${(window.innerWidth - 375) / 2 + 164 + "px"}`,
              }}
            >
              <div className={styles.phoneBannerBtnsTopItemLeft}>
                <img
                  className={styles.phoneBannerBtnsTopItemLeftImg}
                  src={phoneImmediateEviCollect}
                  alt=""
                />
              </div>
              <div className={styles.phoneBannerBtnsTopItemRight}>
                <div className={styles.phoneBannerBtnsTopItemRightTitle}>
                  即时取证
                </div>
                <div className={styles.phoneBannerBtnsTopItemRightSubTitle}>
                  品牌保护，直达公证
                </div>
              </div>
            </div>
          </Link>
          <Link to={{ pathname: "/check" }}>
            <div
              className={styles.phoneBannerBtnsTopItem}
              style={{ width: `${(window.innerWidth - 375) / 2 + 164 + "px"}` }}
            >
              <div className={styles.phoneBannerBtnsTopItemLeft}>
                <img
                  className={styles.phoneBannerBtnsTopItemLeftImg}
                  src={phoneCheckCert}
                  alt=""
                />
              </div>
              <div className={styles.phoneBannerBtnsTopItemRight}>
                <div className={styles.phoneBannerBtnsTopItemRightTitle}>
                  证书查验
                </div>
                <div className={styles.phoneBannerBtnsTopItemRightSubTitle}>
                  上链数据，随时查验
                </div>
              </div>
            </div>
          </Link>
        </div>
        <Link to={{ pathname: "/about", state: { param: "contact" } }}>
          <div
            className={styles.phoneBannerBtnsBottom}
            style={{ width: `${343 + window.innerWidth - 375 + "px"}` }}
          >
            <div className={styles.phoneBannerBtnsBottomLeft}>
              <img
                className={styles.phoneBannerBtnsBottomLeftImg}
                src={phoneBussinessCooperation}
                alt=""
              />
            </div>
            <div className={styles.phoneBannerBtnsBottomMid}>
              <div className={styles.phoneBannerBtnsBottomMidTitle}>
                业务合作
              </div>
              <div className={styles.phoneBannerBtnsBottomMidSubTitle}>
                链上链下，开放创新
              </div>
            </div>
            <div className={styles.phoneBannerBtnsBottomRight}>
              <ArrowRightOutlined
                className={styles.phoneBannerBtnsBottomRightArrow}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      {pcBanner}
      {phoneBanner}
    </>
  );
}
