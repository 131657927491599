import React, { useRef, useEffect, useState, useCallback } from "react";
import { useThrottleFn, useRequest } from "ahooks";
import axios from "axios";
import * as echarts from "echarts/core";
import { TooltipComponent, GeoComponent } from "echarts/components";
import { MapChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { mapJson, provinceMap, provinceEnum } from "./mapData";
import KnotsList from "./KnotsList";
import styles from "./index.module.less";

echarts.use([TooltipComponent, GeoComponent, MapChart, CanvasRenderer]);
const defaultAreaName = "中国";
const getKnotsNum = () => {
  return axios.get(`v1/map/amount`);
};
const mapData = Object.keys(provinceMap).map((name) => {
  return { name, value: 0 };
});

const Index = () => {
  const chartRef = useRef();
  const myChart = useRef();
  const [areaName, setKnotName] = useState(defaultAreaName);

  const { data: knotsNum } = useRequest(getKnotsNum, {
    formatResult: (res) => {
      const list = res?.data?.data;
      if (list instanceof Array) {
        return list;
      }
      return [];
    },
    onSuccess: (list) => {
      mapData.map((item) => {
        const dataItem = list.find(
          (resItem) => provinceEnum[resItem.name] === item.name
        );
        if (dataItem) {
          item.value = dataItem.value;
        }
        return item;
      });
    },
  });

  const { run: runResize } = useThrottleFn(
    () => {
      myChart.current.resize();
    },
    { wait: 300 }
  );

  useEffect(() => {
    myChart.current = echarts.init(chartRef.current);
    initChart(myChart.current);
    window.addEventListener("resize", runResize);
    return () => {
      window.removeEventListener("resize", runResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initChart = (myChart) => {
    const chartLabel = {
      label: {
        show: true,
        color: "#fff",
      },
    };
    echarts.registerMap("china", mapJson);
    myChart.setOption({
      tooltip: {
        trigger: "item",
        formatter: "公证处数量<br/>{b}：{c}",
        backgroundColor: "rgba(5, 11, 20, 0.65)",
        borderColor: "rgba(5, 11, 20, 0.65)",
        padding: [5, 8],
        textStyle: {
          color: "#FFF",
          fontSize: "12",
        },
      },
      series: [
        {
          name: "信证链节点",
          type: "map",
          map: "china",
          left: "1%",
          top: "100",
          width: "670",
          ...chartLabel,
          itemStyle: {
            borderColor: "rgba(255, 255, 255, 0.1)",
            areaColor: "rgba(255, 255, 255, 0.2)",
          },
          emphasis: {
            ...chartLabel,
            itemStyle: {
              areaColor: "rgba(255, 255, 255, 0.3)",
            },
          },
          select: {
            ...chartLabel,
            itemStyle: {
              areaColor: "rgba(255, 255, 255, 0.5)",
            },
          },
          data: mapData,
        },
      ],
    });
    myChart.on("click", function (params) {
      params.event.stop();
      const { name } = params;
      setKnotName((areaName) => {
        if (name === areaName) {
          return defaultAreaName;
        } else {
          return name;
        }
      });
    });
  };

  useEffect(() => {
    const chart = myChart.current;
    if (!chart) {
      return;
    }
    let option = chart.getOption();
    option.series[0].data = mapData;
    chart.setOption(option);
  }, [knotsNum]);

  const onCancelSelect = useCallback(() => {
    myChart.current.dispatchAction({
      type: "unselect",
      name: areaName,
    });
    setKnotName(defaultAreaName);
  }, [areaName]);

  return (
    <>
      <div
        ref={chartRef}
        style={{ width: "100%", height: 667 }}
        onClick={onCancelSelect}
      ></div>
      <div className={styles.knotsList}>
        <KnotsList areaName={areaName} />
      </div>
    </>
  );
};
export default Index;
