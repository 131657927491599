import Header from './components/Header'
import Category from './components/Category'
import How from './components/How'
import Why from './components/Why'
import styles from "./index.module.less";

export default function Index() {
  const onLoginClick = () => {
    window.open('https://dataevidence.wetrustchain.com/user/login', '_blank');
  }
  return (
    <div className={styles.main}>
      <Header onLoginClick={onLoginClick} />
      <div className={styles.sec}>
        <Category onLoginClick={onLoginClick} />
        <How />
        <Why />
      </div>
    </div>
  );
}
