


export const downloadList = [
    {
        label: 'Windws版',
        url: 'https://oss.wetrustchain.com/%E4%BF%A1%E8%AF%81%E9%93%BE%E5%93%88%E5%B8%8C%E8%AE%A1%E7%AE%97%E5%B7%A5%E5%85%B7Setup%201.0.0.zip'
    },
    {
        label: 'MacOS版',
        url: 'https://oss.wetrustchain.com/%E4%BF%A1%E8%AF%81%E9%93%BE%E5%93%88%E5%B8%8C%E8%AE%A1%E7%AE%97%E5%B7%A5%E5%85%B7-1.0.0.dmg'
    },
]