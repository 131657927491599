import styles from "./index.module.less";

export default function Index() {
  const content = [{
    title: '区块链+公信力双背书',
    content: '通过链接区块链技术和公信力机构的力量，为每一份数据提供可信环境和信任背书，保障存证数据可信任、可追溯。'
  }, {
    title: '简便易用，成本低',
    content: '一键存证，即时确权，存证后可获得权威机构颁发的存证证书。'
  }, {
    title: '数据安全',
    content: '平台服务全链路采用安全加密机制，从根本上保障数据安全。'
  }]

  return (
    <>
      <div className={styles.title} />
      <div className={styles.main}>
        {content.map(item => (
          <div className={styles.card}>
            <div className={styles.cardTitle}>{item.title}</div>
            <div className={styles.cardBody}>{item.content}</div>
          </div>
        ))}
      </div>
    </>
  );
}