import React from "react";
import Footer from "../../components/NewComponents/Footer";
import Header from "../../components/NewComponents/Header";
import Banner from "../../components/NewComponents/Banner";
import HomePageBottom from "../../components/NewComponents/HomePageBottom";
import SideButton from "../../components/NewComponents/SideButton";

export default function HomePage() {
  return (
    <div style={{ background: '#F9FAFE' }}>
      <Header selectedKeys={["home"]} />
      <Banner />
      <HomePageBottom />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </div>
  );
}
