import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
// import { CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useRequest } from "@umijs/hooks";
import styles from "./index.module.less";
import arrow from "../../../assets/zjapp_icon/arrow.png";
// import logoAndroid from "../../../assets/newImg/appDownloadImg/logoAndroid.png";
// import logoWeChat from "../../../assets/newImg/appDownloadImg/logoWeChat.png";
// import logoQr from "../../../assets/newImg/appDownloadImg/logoQr.png";
import appIconMobile from "../../../assets/newImg/appDownloadImg/appIconMobile@2x.png";
import axios from "axios";
// import QRCode from "qrcode.react";
import zjAppPic1 from "../../../assets/newImg/appDownloadImg/zjAppPic1@2x.png";
import zjAppPic2 from "../../../assets/newImg/appDownloadImg/zjAppPic2@2x.png";
import zjAppPic3 from "../../../assets/newImg/appDownloadImg/zjAppPic3@2x.png";
import web from "../../../assets/newImg/appDownloadImg/网页取证.png";
import video from "../../../assets/newImg/appDownloadImg/录屏取证.png";
import live from "../../../assets/newImg/appDownloadImg/现场取证.png";
import record from "../../../assets/newImg/appDownloadImg/录音取证.png";
import data from "../../../assets/newImg/appDownloadImg/数据存证.png";
import check from "../../../assets/newImg/appDownloadImg/check.png";
import how from "../../../assets/newImg/appDownloadImg/useZj.png";
import liveStreaming from "../../../assets/newImg/appDownloadImg/liveStreaming@2x.png";
import storefront from "../../../assets/newImg/appDownloadImg/storefront@2x.png";
import intellectualProperty from "../../../assets/newImg/appDownloadImg/intellectualProperty@2x.png";
import publicSentiment from "../../../assets/newImg/appDownloadImg/publicSentiment@2x.png";
import voiceRecording from "../../../assets/newImg/appDownloadImg/voiceRecording@2x.png";
import chattingHistory from "../../../assets/newImg/appDownloadImg/chattingHistory@2x.png";
import phoneIcon from "../../../assets/newImg/appDownloadImg/phoneIcon@2x.png";
import qrcodeIcon from "../../../assets/newImg/appDownloadImg/qrcodeIcon@2x.png";
import pcIcon from "../../../assets/newImg/appDownloadImg/pcIcon@2x.png";
import arrowRightIcon from "../../../assets/newImg/appDownloadImg/arrowRightIcon@2x.png";
import classNames from "classnames";

const qrList = [
  // {
  //   key: 'IOS',
  //   logo: logoIOS,
  //   logoDesc: '证解iOS客户端',
  //   alt: '证解iOS客户端下载二维码',
  // },
  // {
  //   key: 'Android',
  //   logo: logoAndroid,
  //   logoDesc: '证解Android客户端',
  //   alt: '证解Android客户端下载二维码',
  // },
  {
    key: "phoneIcon",
    logo: phoneIcon,
    logoDesc: "证解移动端",
    alt: "证解客户端下载二维码",
  },
  {
    key: "pcIcon",
    logo: pcIcon,
    logoDesc: "证解PC端",
    alt: "证解PC端",
  },
];

const candoList = ['现场取证', '录音取证', '录屏取证', '网页取证', '远程取证', '申请公证', '证据管理', '在线签署', '保全证书', '身份认证'];

const contentBlocks = [
  {
    heading: "区块链 + 公信力双背书",
    outline:
      "通过链接区块链技术和公信力机构的力量，为每一份数据提供可信环境和信任背书，让数据转化为承载着契约精神和法律效力的“证据”",
  },
  {
    heading: "降低维权成本，随时随地取证",
    outline:
      "满足不在公证员工作时间段的取证场景、需要公证员外出异地的取证场景、随机发生的预防性取证场景的取证需求，即时存取保全，降低风险和成本",
  },
  {
    heading: "操作简便，证据规范化管理",
    outline:
      "满足多类取证需求，界面友好，学习成本低；自定义标签体系，海量证据可根据标签分类管理；权限控制，协同取证，保障证据质量和效用最大化",
  },
  {
    heading: "支持全国范围申请公证",
    outline:
      "上链证据可在3年有效期内选择全国范围27个省市公证处出具公证书，申请公证后，电子证据信息直达27家公证处业务系统",
  },
];

const introBlocks = [
  {
    title: '网页取证',
    intro: '可以无需登录的静态图文网页进行取证保全，且支持批量操作',
    environment: '新闻资讯、舆情管理、名誉保护、微信公众号、百家号等',
    img: web,
  },
  {
    title: '录屏取证', 
    intro: '通过录屏将用户在线操作过程及界面内容进行录制并生成可信证据包',
    environment: '电商购物、直播平台、聊天记录、音视频播放、社交媒体等',
    img: video,
  },
  {
    title: '现场取证',
    intro: '也称录像取证，对录像设备进行情节性检查并获取地理经纬度位置，对现场环境进行实况录制',
    environment: '线下购物、品牌打假、房屋租赁、日常巡店、签到打卡等',
    img: live,
  },
  {
    title: '录音取证',
    intro: '对录音设备进行情节性检查并获取地理经纬度位置，实时录音并形成可信音频证据包',
    environment: '通话记录、商务谈判、新闻采访、人物访谈、会议发言等',
    img: record,
  },
  {
    title: '数据存证',
    intro: '以区块链为底层技术为用户提供存证确权服务',
    environment: '版权服务、文字作品、图像作品、合同管理、商业秘密等',
    img: data,
  }
]

const imgBlocks = [
  {
    pic: liveStreaming,
    caption: "直播打假",
  },
  {
    pic: storefront,
    caption: "门店打假",
  },
  {
    pic: intellectualProperty,
    caption: "知识侵权",
  },
  {
    pic: publicSentiment,
    caption: "舆情取证",
  },
  {
    pic: voiceRecording,
    caption: "录音取证",
  },
  {
    pic: chattingHistory,
    caption: "聊天记录",
  },
];
function isWeiXin(){
  let ua = window.navigator.userAgent.toLowerCase();
  if(ua.match(/micromessenger/i)?.[0] === 'micromessenger'){
    return true;
  }else{
    return false;
  }
}
export default function Index() {
  const location = useLocation();
  const [phoneQrCode, setPhoneQrCode] = useState('');
  const [androidDownloadLink, setAndroidDownloadLink] = useState("");
  const [maskVisible, setMaskVisible] = useState(true);
  const [introIndex, setIntroIndex] = useState(0);

  useEffect(() => {
    // 当路由切换时 滚动到页面顶部
    window.scrollTo(0, 0);
  }, [location]);

  const getPhoneQrCode = async () => {
    const result = await axios.get('/v1/zjapp/qrcode');
    setPhoneQrCode(result?.data?.data);
  }
  useEffect(() => {
    getPhoneQrCode();
  }, []);

  function requestAndroidDownloadLink() {
    return axios.get("/v1/app/qrcode");
  }
  const { run: getAndroidDownloadLink } = useRequest(
    requestAndroidDownloadLink,
    {
      manual: true,
    }
  );
  const getQrCode = async () => {
    const result = await getAndroidDownloadLink();
    setAndroidDownloadLink(result?.data?.data);
  };
  useEffect(() => {
    getQrCode();
  }, []);
  const handleDownload = () => {
    const us = navigator.userAgent;
    const isAndroid = us.indexOf("Android") > -1 || us.indexOf("Linux") > -1;
    const isiOS = !!us.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      window.open(androidDownloadLink);
    } else if (isiOS) {
      window.open(
        "https://apps.apple.com/cn/app/%E8%AF%81%E8%A7%A3/id1569527954"
      );
    }
  };

  const handleJumpToTrail = () => {
    const newPage = window.open("about:blank");
    newPage.location.href = "https://evidence.wetrustchain.com/user/login";
  };

  const pcZjapp = (
    <div className={styles.appDownload}>
      <div className={styles.sectionAppDownload}>
        <div className={styles.section0}>
          <div className={styles.Pic1}>
            <img className={styles.Pic1Img} src={zjAppPic1} />
            <div className={styles.Cando}>
              {candoList.map((v) => <div className={styles.CandoItem}>
                <img className={styles.CandoImg} src={check} />
                {v}
              </div>)}
            </div>
          </div>
          <div className={styles.Pic2}>
            <img src={zjAppPic2} />
          </div>
        </div>

        <div className={styles.qrs}>
          {qrList.map((item) => {
            return (
              <div
                className={styles.qrComponent}
                key={item.key}
                onClick={item.key === "pcIcon" ? handleJumpToTrail : null}
              >
                <div
                  className={`${styles.qr} ${item.key === "phoneIcon" ? styles.qrPhone : null
                    } ${item.key === "pcIcon" ? styles.qrWeChat : null}`}
                >
                  <div className={styles.phoneQrCode}>
                    <img src={phoneQrCode} alt="" />
                  </div>
                  <div className={styles.qrBg}>
                    <img
                      className={styles.qrImg}
                      src={
                        item.key === "pcIcon" ? arrowRightIcon : qrcodeIcon
                      }
                      alt="二维码图标"
                    />
                  </div>
                  <img
                    className={styles.centerImg}
                    src={item.logo}
                    alt={item.alt}
                  />
                </div>
                <div className={styles.logoDesc}>{item.logoDesc}</div>
              </div>
            );
          })}
        </div>

        <div className={styles.intro}>
          <div className={styles.introHeader}>
            {introBlocks.map((v, i) => {
              console.log(introIndex === i);
              return <a key={v.title} onClick={() => setIntroIndex(i)} className={classNames(styles.introHeaderBlock, introIndex === i && styles.introHeaderBlockSelected)}>
                <img alt="i" src={v.img} />
                {v.title}
              </a>
            })}
          </div>
          <div className={styles.introContent}>
            <div className={styles.introContentTitle}>{introBlocks[introIndex].title}</div>
            <div className={styles.introContentIntro}>{introBlocks[introIndex].intro}</div>
            <div className={styles.introContentEnvironment}>使用场景：{introBlocks[introIndex].environment}</div>
          </div>
          <img alt="i" src={introBlocks[introIndex].img} className={styles.introImage} />
        </div>

        {/* 用数组优化下 */}
        <div className={styles.section1}>
          <div className={styles.sectionHeader}>
            <div className={styles.text}>
              <div className={styles.ch}>如何使用证解</div>
              <div className={styles.en}>HOW</div>
            </div>
            <div className={styles.howImg}>
              <img src={how} />
            </div>
            <div className={styles.text2}>
              <div className={styles.ch}>为什么选择证解</div>
              <div className={styles.en}>WHY</div>
            </div>
          </div>
          <div className={styles.contentBlock}>
            {contentBlocks.map((item) => {
              return (
                <div className={styles.sectionContent}>
                  <div className={styles.heading}>
                    <div className={styles.littleBlueRect}></div>
                    {item.heading}
                  </div>
                  <div className={styles.outline}>{item.outline}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.section2}>
          <div className={styles.sectionHeader}>
            <div className={styles.text}>
              <div className={styles.ch}>产品介绍</div>
              <div className={styles.en}>PRODUCT INTRODUCTION</div>
            </div>
          </div>
          <div className={styles.sectionContent11}>
            <div className={styles.outline}>
              证解是全国性运营级公信联盟区块链“信证链"的原生电子证据专家。通过手机APP和PC端为用户提供基于区块链技术的网页取证、现场取证、录屏取证、录音取证等功能,全方位、多途径协助用户实现不同形式电子数据的存取证管理,实现电子数据证据化、取证方式多样化、证据使用便利化。
              <Button className={styles.contactBtn} onClick={handleJumpToTrail}>
                申请试用
              </Button>
            </div>
            <div className={styles.Pic3}>
              <img src={zjAppPic3} />
            </div>
          </div>
        </div>

        <div className={styles.section3}>
          <div className={styles.sectionHeader}>
            <div className={styles.text}>
              <div className={styles.ch}>应用场景</div>
              <div className={styles.en}>APPLICATION FIELD</div>
            </div>
          </div>
          <div className={styles.sectionContent2}>
            {imgBlocks.map((item) => {
              return (
                <div className={styles.imgWrapper}>
                  <img src={item.pic} />
                  <div className={styles.caption}>{item.caption}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  const phoneAppDownload = (
    <div className={styles.phoneAppDownload}>
      <div className={styles.phoneAppDownloadContent}>
        <img
          src={appIconMobile}
          className={styles.phoneAppDownloadContentIcon}
          alt=""
        />
        <div className={styles.phoneAppDownloadContentDesc}>
          <div className={styles.phoneAppDownloadContentDescTitle}>
            证解客户端
          </div>
          <div className={styles.phoneAppDownloadContentDescSubtitle}>
            立即下载，让信任即刻传递
          </div>
        </div>
        <Button
          className={styles.phoneAppDownloadContentBtn}
          onClick={isWeiXin()?()=>setMaskVisible(true):handleDownload}
        >
          下载证解客户端
        </Button>
      </div>
    </div>
  );
  return (
    <>
      {
        isWeiXin() && maskVisible && (
          <div className={styles.mask} onClick={()=>setMaskVisible(false)}>
            <img src={arrow} alt="箭头" className={styles.maskImg}/>
            <div className={styles.maskText}>
              <div>点击右上角“…” </div>
              <div>选择在默认浏览器中打开</div>
            </div>
          </div>
        )
      }
      {pcZjapp}
      {phoneAppDownload}
    </>
  );
}
