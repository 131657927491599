import React from "react";
import Footer from "../../components/NewComponents/Footer";
import AboutMenu from "../../components/NewComponents/AboutMenu";
import SideButton from "../../components/NewComponents/SideButton";
import Header from "../../components/NewComponents/Header";

export default function About() {
  return (
    <>
      <Header selectedKeys={["about"]} />
      <AboutMenu />
      <SideButton label="联系我们" url="./contact" />
      <Footer />
    </>
  );
}
