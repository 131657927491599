import React, { useCallback } from "react";
import { message, Upload, Form } from "antd";
import { FolderOpenOutlined } from "@ant-design/icons";
import styles from "./index.module.less";

const { Dragger } = Upload;
const MaxSizeNumebr = 4 //4G
const MaxSize = 1024 * MaxSizeNumebr;
const Index = (props) => {
  const { onSubmit } = props;
  const [form] = Form.useForm();

  const handleFileSubmit = (file) => {
    if (file) {
      onSubmit(file);
      return;
    }
    message.error("请上传存证文件");
  };

  const normFile = useCallback((e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.formWrap}>
        <Form
          name="form"
          form={form}
          requiredMark={false}
          className={styles.form}
        >
          <Form.Item
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Dragger
              maxCount={1}
              multiple={false}
              beforeUpload={(file, fileList) => {
                return new Promise((resolve, reject) => {
                  const isMax = file.size / 1024 / 1024 < MaxSize;
                  if (!isMax) {
                    return message.error("文件大小不能超过" + MaxSizeNumebr + "G!");
                  }
                  if (isMax) {
                    resolve();
                  }
                  resolve();
                });
              }}
              customRequest={async ({ file, filename, onSuccess, onError }) => {
                const isMax = file.size / 1024 / 1024 < MaxSize;
                if (!isMax) {
                  message.error("文件大小不能超过" + MaxSizeNumebr + "G!");
                }
                if (isMax) {
                  const checkFile = {
                    file,
                    name: form.getFieldValue("file")[0]?.name,
                  };
                  handleFileSubmit(checkFile);
                  onSuccess();
                } else {
                  onError();
                }
              }}
              onRemove={async () => {
                return true;
              }}
            >
              <div className={styles.uploadIcon}>
                <FolderOpenOutlined />
              </div>
              <p className={styles.text}>点击或拖拽上传文件</p>
              <p className={styles.uploadTips}>
                请上传完整证据包文件，文件大小应不超过{MaxSizeNumebr}G
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.bottom}>
        <div className={styles.illustration}>
          <div>查验说明：</div>
          <div>
            1、信证查验支持证解、信监保、信证通和可信执法仪等信证链产品的上链数据的查验。
          </div>
          <div>
            2、哈希查验需输入完整的证据哈希或者证据短哈希（证据哈希前六位）进行查验。
          </div>
          <div>
            3、文件校验需上传完整的证据包进行校验。校验信监保证据包时需解压证据总包，再使用总包文件夹中的证据包文件进行校验。
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
