import React from "react";
import Footer from "../../components/NewComponents/Footer";
import News from "../../components/NewComponents/News";
import Header from "../../components/NewComponents/Header";
import SideButton from "../../components/NewComponents/SideButton";

export default function NewsPage() {
  return (
    <>
      <Header selectedKeys={["news"]} />
      <News />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </>
  );
}
