import React, { useRef, useState, useEffect } from "react";
import { Carousel } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import styles from "./index.module.less";
import InfoCard from "../InfoCard";
import KnotsMap from "../KnotsMap";
import allianceChain from "../../../assets/newImg/homePageImg/allianceChain.png";
import distributedData from "../../../assets/newImg/homePageImg/distributedData.png";
import multiscene from "../../../assets/newImg/homePageImg/multiscene@2x.png";
import fundamental from "../../../assets/newImg/homePageImg/fundamental@2x.png";
import strengthImgAllianceChain from "../../../assets/newImg/homePageImg/strengthImgAllianceChain@2x.png";
import strengthImgDistributedData from "../../../assets/newImg/homePageImg/strengthImgDistributedData@2x.png";
import strengthImgFundamental from "../../../assets/newImg/homePageImg/strengthImgFundamental@2x.png";
import strengthImgMultiscene from "../../../assets/newImg/homePageImg/strengthImgMultiscene@2x.png";
import remoteCollaboration from "../../../assets/newImg/homePageImg/remoteCollaboration.png";
import remoteVideo from "../../../assets/newImg/homePageImg/remoteVideo.png";
import immediateEvidenceCollection from "../../../assets/newImg/homePageImg/immediateEvidenceCollection.png";
import onlineStrengthen from "../../../assets/newImg/homePageImg/onlineStrengthen.png";
import ourKnotsMobile from "../../../assets/newImg/homePageImg/ourKnotsMobile@2x.png";
import intellectualProperty from "../../../assets/newImg/homePageImg/intellectualProperty.png";
import marketSurveillance from "../../../assets/newImg/homePageImg/marketSurveillance.png";
import arts from "../../../assets/newImg/homePageImg/arts.png";
import retails from "../../../assets/newImg/homePageImg/retails.png";
import culturalInnovation from "../../../assets/newImg/homePageImg/culturalInnovation.png";
import finance from "../../../assets/newImg/homePageImg/finance.png";
import financeMobile from "../../../assets/newImg/homePageImg/financeMobile@2x.png";
import realEstate from "../../../assets/newImg/homePageImg/realEstate.png";
import realEstateMobile from "../../../assets/newImg/homePageImg/realEstateMobile@2x.png";
import affairs from "../../../assets/newImg/homePageImg/affairs.png";
import internetSurveillance from "../../../assets/newImg/homePageImg/internetSurveillance.png";
import localGovernance from "../../../assets/newImg/homePageImg/localGovernance.png";
import localGovernanceMobile from "../../../assets/newImg/homePageImg/localGovernanceMobile@2x.png";
import moduleNameCoreStrengths from "../../../assets/newImg/homePageImg/moduleNameCoreStrengths@2x.png";
import moduleNameApplicableScenes from "../../../assets/newImg/homePageImg/moduleNameApplicableScenes@2x.png";
import moduleNameOurKnots from "../../../assets/newImg/homePageImg/moduleNameOurKnots@2x.png";
import moduleNameOurServices from "../../../assets/newImg/homePageImg/moduleNameOurServices@2x.png";
import ServiceCard from "./components/ServiceCard";
import axios from "axios";
import { useRequest } from "@umijs/hooks";
import NewsCard from "./components/NewsCard";
import serviceRings from "../../../assets/serviceRings/serviceRings@2x.png";
import zjapp_icon from "../../../assets/zjapp_icon/zjapp@2x.png";
import xysc_icon from "../../../assets/xysc_icon/xysc@2x.png";
import kxzfy_icon from "../../../assets/kxzfy_icon/kxzfy@2x.png";
import newsBg from "../../../assets/newsBg@2x.png";

const coreStrengths = [
  {
    logo: allianceChain,
    title: "具备公信力的权威联盟链",
    key: "allianceChain",
    num: 0,
    img: strengthImgAllianceChain,
    desc:
      "全国范围公信力机构作为节点的联盟链，弥补了区块链技术在上链前数据真实性审核上的缺失，符合各项监管要求。",
  },
  {
    logo: distributedData,
    title: "价值互联网可信基础设施",
    key: "distributedData",
    num: 1,
    img: strengthImgDistributedData,
    desc:
      "联通物理世界与数字世界。支持数据证据化、数据资产化服务。为链上每一份电子证据和数字资产提供安全的信任背书。",
  },
  {
    logo: multiscene,
    title: "多场景链上链下信任协同",
    key: "multiscene",
    num: 2,
    img: strengthImgMultiscene,
    desc:
      "“筑链成网”实现全国丰富场景数据协同，有力支撑更高层次的各类社会场景中需要完善信任机制的创新应用。",
  },
  {
    logo: fundamental,
    title: "国产自主可控区块链底层",
    key: "fundamental",
    num: 3,
    img: strengthImgFundamental,
    desc:
      "符合国家战略安全规划的区块链核心技术平台。完全国产的区块链底层技术，从根本上保障数据安全。",
  },
];
const applicableScene = [
  {
    img: internetSurveillance,
    imgMobile: internetSurveillance,
    name: "网络监管",
    completeDesc:
      "区块链+互联网信息违法违规内容全方位监管，实现网络舆情等信息的可信存证、取证与固证",
  },
  {
    img: intellectualProperty,
    imgMobile: intellectualProperty,
    name: "知识产权",
    completeDesc:
      "支持多维度、多途径区块链取证固证，为可信存证、确权保护与价值流转进行全生命周期的保驾护航",
  },
  {
    img: marketSurveillance,
    imgMobile: marketSurveillance,
    name: "市场监管",
    completeDesc:
      "区块链赋能市场监管执法取证，网络交易等电子证据全流程监管与证据追溯",
  },
  {
    img: finance,
    imgMobile: financeMobile,
    name: "金融征信",
    completeDesc:
      "基于区块链的低成本、高安全性、高效率的新型征信模型，将资金、凭证等资产数字化，实现数据交易共享、打破数据孤岛",
  },
  {
    img: realEstate,
    imgMobile: realEstateMobile,
    name: "地产租赁",
    completeDesc:
      "用户身份认证及房屋质量把控，不动产租赁交易数据全流程隐私保护与证据追溯",
  },
  {
    img: localGovernance,
    imgMobile: localGovernanceMobile,
    name: "基层治理",
    completeDesc:
      "通过智能合约和多方共识基础设施的构建，实现组织扁平化、业务协同化、数据证据化，降低基层治理信用风险，提升基层治理效力与效率",
  },
  {
    img: affairs,
    imgMobile: affairs,
    name: "外事侨务",
    completeDesc:
      "侨情调研、招商引资、文化交流等场景的数据可信共享，推动国家治理体系与治理能力现代化",
  },
  {
    img: arts,
    imgMobile: arts,
    name: "艺术收藏",
    completeDesc:
      "支持企业级数字藏品平台并提供“一站式”部署服务，确保数字藏品价值的公开、透明、可信，为企业、政府、IP所有者提供“数字藏品的可信基础设施”",
  },
  {
    img: culturalInnovation,
    imgMobile: culturalInnovation,
    name: "文化创意",
    completeDesc:
      "提供基于区块链技术的知识产权保护解决方案，降低盗版规制成本、提高价值协同效率，有效规避文化创意产业中成果产权不清、创意及知识财产流失、产权交易混乱等问题",
  },
  {
    img: retails,
    imgMobile: retails,
    name: "零售消费",
    completeDesc:
      "通过分布式记账与智能合约实现原材料及供应商、生产、仓储、运输、销售信息产业链透明化，提升消费者信任度",
  },
];
const ourServices = [
  {
    name: "异地协同",
    desc: "公信联盟链原生数据协同平台",
    logo: remoteCollaboration,
    key: "remoteCollaboration",
    completeDesc:
      "参照网络交易的模式，各业务节点可以根据实际情况，将自身可以对外提供的数据服务在平台上架并标价。相关服务的需求方和供给方在按价支付服务费用和平台使用费后，在链上实现数据的可信安全高效共享。",
  },
  {
    name: "远程视频",
    desc: "公信联盟链原生视频公证助手",
    logo: remoteVideo,
    key: "remoteVideo",
    completeDesc:
      "提供从身份验证、文件上传、时间预约、业务发布、远程视频到在线签署的全流程链上服务体验。杜绝传统视频公证中常见的线下签署、线下寄送等“脱机”环节，确保了远程视频业务的完整性与公正性。",
  },
  {
    name: "即时取证",
    desc: "公信联盟链原生电子证据专家",
    logo: immediateEvidenceCollection,
    key: "immediateEvidenceCollection",
    completeDesc:
      "针对直播等新型传播手段对电子证据在即时性、合法性和有效性等方面提出的新要求，信证链上架了公信联盟链原生电子证据专家，包括了手机录屏、拍照摄像、录音取证和网页取证在内的多种区块链取证能力。真正将电子证据从以往单纯的存证，向“取、存、管、用”的全生命周期扩展。",
  },
  {
    name: "在线赋强",
    desc: "公信联盟链原生在线签约平台",
    logo: onlineStrengthen,
    key: "onlineStrengthen",
    completeDesc:
      "在远程视频功能的基础上，嵌入合同模板、多方签署、风险提示等应用模块，形成可以适用于多方同时签署的在线视频签约平台，从而为司法部提出的在办理在线赋强业务过程中“要逐件办理”、“不得自动批量办理”、“应当由公证员亲自办理”等要求提供公信联盟链原生解决方案。",
  },
];

SwiperCore.use([Navigation]);
const SLIDE_WIDTH = 327 + window.innerWidth - 375;
const PADDING_LR = 32;
const Index = () => {
  const [dataNews, setDataNews] = useState({});
  const pageSize = 3;
  function requestNews(params) {
    return axios.get(
      `/v1/info?pageNo=${params.pageNo}&pageSize=${params.pageSize}`
    );
  }
  const { run: getNews } = useRequest(requestNews, {
    paginated: true,
    manual: true,
  });
  const refreshData = async (pageNumber) => {
    const result = await getNews({ pageNo: pageNumber, pageSize });
    setDataNews(result?.data);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    refreshData(1);
  }, []);
  const carouselRef = useRef(null);
  const [coreLogoSelectedNum, setCoreLogoSelectedNum] = useState(0);
  const clickCoreImg = (item) => {
    if (item.num !== coreLogoSelectedNum) {
      carouselRef.current.goTo(item.num);
      setCoreLogoSelectedNum(item.num);
    }
  };
  useEffect(() => {
    carouselRef.current.goTo(0);
  }, [coreLogoSelectedNum]);

  const clickLeftArrow = () => {
    if (coreLogoSelectedNum === 0) {
      setCoreLogoSelectedNum(3);
    } else if (coreLogoSelectedNum === 1) {
      setCoreLogoSelectedNum(0);
    } else if (coreLogoSelectedNum === 2) {
      setCoreLogoSelectedNum(1);
    } else if (coreLogoSelectedNum === 3) {
      setCoreLogoSelectedNum(2);
    }
  };
  const clickRightArrow = () => {
    if (coreLogoSelectedNum === 0) {
      setCoreLogoSelectedNum(1);
    } else if (coreLogoSelectedNum === 1) {
      setCoreLogoSelectedNum(2);
    } else if (coreLogoSelectedNum === 2) {
      setCoreLogoSelectedNum(3);
    } else if (coreLogoSelectedNum === 3) {
      setCoreLogoSelectedNum(0);
    }
  };
  const arrowLeft = (
    <div>
      <div
        className={`${styles.switchBtn}`}
        onClick={() => clickLeftArrow()}
      >
        <div className={`${styles.arrow} ${styles.arrowLeft}`}></div>
      </div>
    </div>
  );
  const arrowRight = (
    <div>
      <div
        className={`${styles.switchBtn}`}
        onClick={() => clickRightArrow()}
      >
        <div className={`${styles.arrow} ${styles.arrowRight}`}></div>
      </div>
    </div>
  );

  const phoneHomePage = (
    <div className={styles.phoneHomePage}>
      <div className={styles.phoneHomePageCoreStrengths}>
        <div className={styles.phoneModuleName}>
          <img
            className={styles.phoneModuleNameImg}
            src={moduleNameCoreStrengths}
            alt=""
          />
        </div>
        <div className={styles.phoneHomePageCoreStrengthsSwiper}>
          <Swiper
            slidesPerView={window.innerWidth / SLIDE_WIDTH - 0.05}
            initialSlide={0}
            loop={true}
          >
            {coreStrengths.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div>
                    <div
                      className={styles.phoneHomePageCoreStrengthsBox}
                      style={{
                        width: `${window.innerWidth + 308 - 375 + "px"}`,
                      }}
                    >
                      <div className={styles.phoneHomePageCoreStrengthsBoxTop}>
                        <img
                          className={
                            styles.phoneHomePageCoreStrengthsBoxTopLogo
                          }
                          src={item.logo}
                          alt=""
                        />
                        <div
                          className={
                            styles.phoneHomePageCoreStrengthsBoxTopTitle
                          }
                        >
                          {item.title}
                        </div>
                      </div>
                      <div
                        className={styles.phoneHomePageCoreStrengthsBoxBottom}
                      >
                        <div
                          className={
                            styles.phoneHomePageCoreStrengthsBoxBottomDesc
                          }
                        >
                          {item.desc}
                        </div>
                        <img
                          class={styles.phoneHomePageCoreStrengthsBoxBottomImg}
                          src={item.img}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className={styles.phoneHomePageOurServices}>
        <div className={styles.phoneModuleName}>
          <img
            className={styles.phoneModuleNameImg}
            src={moduleNameOurServices}
            alt=""
          />
        </div>
        {ourServices.map((item) => {
          return (
            <InfoCard
              defaultShow={false}
              key={item.key + "mobile"}
              logo={item.logo}
              name={item.name}
              content={item.completeDesc}
              width={`${window.innerWidth + 343 - 375 + "px"}`}
            />
          );
        })}
      </div>

      <div className={styles.phoneHomePageOurKnots}>
        <div className={styles.phoneModuleName}>
          <img
            className={styles.phoneModuleNameImg}
            src={moduleNameOurKnots}
            alt=""
          />
        </div>
        <div className={styles.phoneHomePageOurKnotsImgContainer}>
          <img
            className={styles.phoneHomePageOurKnotsImg}
            src={ourKnotsMobile}
            width={window.innerWidth}
            alt=""
          />
        </div>
      </div>

      <div className={styles.phoneHomePageApplicableScenes}>
        <div className={styles.phoneModuleName}>
          <img
            className={styles.phoneModuleNameImg}
            src={moduleNameApplicableScenes}
            alt=""
          />
        </div>
        <div
          className={styles.phoneHomePageApplicableScenesContainer}
          style={{ width: `${window.innerWidth - PADDING_LR + "px"}` }}
        >
          {applicableScene.map((item) => {
            return (
              <div
                className={styles.phoneHomePageApplicableSceneExample}
                style={{
                  background: `url(${item.imgMobile})`,
                  backgroundSize: "cover",
                  width: `${window.innerWidth - PADDING_LR + "px"}`,
                  height: `${((window.innerWidth - PADDING_LR) * 120) / 342 + "px"
                    }`,
                }}
              >
                <div className={styles.phoneHomePageApplicableSceneExampleName}>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const pcHomePage = (
    <div className={styles.pcHomePage}>
      <div className={styles.coreStrengths}>
        <div className={styles.sectionCoreStrengths}>
          <div className={styles.moduleName}>
            <div className={styles.ch}>核心优势</div>
            <div className={styles.en}>CORE STRENGTHS</div>
          </div>
          <div className={styles.strengthsBox}>
            {coreStrengths.map((item) => {
              return (
                <div
                  className={styles.strength}
                  key={item.key}
                  onClick={() => clickCoreImg(item)}
                >
                  <div
                    className={
                      item.num === coreLogoSelectedNum
                        ? styles.logoContainerSelected
                        : styles.logoContainerNotSelected
                    }
                  >
                    <img
                      className={styles.strengthLogo}
                      src={item.logo}
                      alt={item.key}
                    />
                  </div>
                  <div className={styles.logoDesc}>{item.title}</div>
                </div>
              );
            })}
          </div>
          <div className={styles.strengthDesc}>
            <div className={styles.strengthDescArrow} style={{ left: `${(coreLogoSelectedNum * 25 + 12.5)}%` }}></div>
            <Carousel
              arrows={true}
              dots={false}
              effect="fade"
              prevArrow={arrowLeft}
              nextArrow={arrowRight}
              ref={carouselRef}
            >
              {coreStrengths.map((item) => {
                return (
                  <>
                    <div className={styles.strengthDescContainer}>
                      <img
                        className={styles.strengthImg}
                        src={item.img}
                        alt="strengthImg"
                        style={{ width: 349, height: 300 }}
                      />
                      <div className={styles.description}>{item.desc}</div>
                    </div>
                  </>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>

      <div className={styles.ourServices}>
        <div className={styles.sectionOurService}>
          <div className={styles.moduleName}>
            <div className={styles.ch}>信证服务</div>
            <div className={styles.en}>OUR SERVICES</div>
          </div>

          <div className={styles.serviceList}>
            <div
              className={styles.innerChunk}
              onClick={() => (window.location.href = "/zjapp")}
            >
              <div className={styles.logoAndSvcName}>
                <div className={styles.upper}>
                  <div className={styles.forgiveMe}>
                    <img className={styles.image} src={zjapp_icon} />
                    <div className={styles.svcName}>证解</div>
                  </div>
                  <div className={styles.gg}></div>
                </div>
                <div className={styles.caption}>即时取证的“最优解”</div>
              </div>
            </div>
            {/* <div
              className={styles.innerChunk}
              onClick={() => (window.location.href = "/xysc")}
            >
              <div className={styles.logoAndSvcName}>
                <div className={styles.upper}>
                  <div className={styles.forgiveMe}>
                    <img className={styles.image} src={xysc_icon} />
                    <div className={styles.svcName}>信元数藏</div>
                  </div>
                  <div className={styles.gg}></div>
                </div>
                <div className={styles.caption}>可信元宇宙的“铸藏师”</div>
              </div>
            </div> */}
            <div
              className={styles.innerChunk}
              onClick={() => (window.location.href = "/kxzfy")}
            >
              <div className={styles.logoAndSvcName}>
                <div className={styles.upper}>
                  <div className={styles.forgiveMe}>
                    <img className={styles.image} src={kxzfy_icon} />
                    <div className={styles.svcName}>可信执法仪</div>
                  </div>
                  <div className={styles.gg}></div>
                </div>
                <div className={styles.caption}>数据可信的“第一公里”</div>
              </div>
            </div>
          </div>
          <div className={styles.serviceRings}>
            <img src={serviceRings} />
          </div>
        </div>
      </div>

      <div className={styles.ourKnots}>
        <div className={styles.sectionOurKnots}>
          <div className={styles.moduleNameLight}>
            <div className={styles.ch}>信证链节点</div>
            <div className={styles.en}>OUR KNOTS</div>
          </div>
          <KnotsMap />
        </div>
      </div>
      <div className={styles.newsScene}>
        <div className={styles.sectionNewsScene}>
          <div className={styles.moduleNameCopy}>
            <div className={styles.infoContainer}>
              <div className={styles.leftCol}>
                <div className={styles.ch}>资讯动态</div>
                <div className={styles.en}>NEWS</div>
              </div>
              <div className={styles.rightCol}>
                <a className={styles.nn} href="/news">
                  查看更多
                </a>
                <div className={styles.pp}></div>
              </div>
            </div>
            <div className={styles.newsBox}>
              {dataNews?.data?.list?.map((item) => {
                return (
                  <NewsCard
                    url={item.url}
                    key={item.id}
                    cover={item.cover}
                    mainTitle={item.mainTitle}
                    subtitle={item.subtitle}
                    createTime={item.createTime}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.applicableScene}>
        <div className={styles.sectionApplicableScene}>
          <div className={styles.moduleName}>
            <div className={styles.ch}>适用行业</div>
            <div className={styles.en}>APPLICABLE SCENE</div>
          </div>
          <div className={styles.sceneExamples}>
            {applicableScene.map((item) => {
              return (
                <ServiceCard
                  name={item.name}
                  key={item.key}
                  img={item.img}
                  logo={item.img}
                  desc={item.desc}
                  infomation={item.completeDesc}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {pcHomePage}
      {phoneHomePage}
    </>
  );
};

export default Index;
