import React, { useState, useEffect } from "react";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useRequest } from "@umijs/hooks";
import styles from "./index.module.less";
import phoneTopImg from "../../../assets/newImg/documentImg/documentPhoneTopImg@2x.png";
import axios from "axios";

const { SubMenu } = Menu;

export default function Index() {
  const [selectedKey, setSelectedKey] = useState();
  const [selectedDoc, setSelectedDoc] = useState();
  const [selectedDocTitle, setSelectedDocTitle] = useState();
  const [directoryList, setDirectoryList] = useState();
  const [docList, setDocList] = useState();
  const [curDocIdx, setCurDocIdx] = useState(0);
  const [maxDocIdx, setMaxDocIdx] = useState(0);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  function requestDirectoryList() {
    return axios.get("/v1/document/catalog");
  }
  const { run: getDirectoryList } = useRequest(requestDirectoryList, {
    manual: true,
  });

  function requestDocList(key) {
    return axios.get(`/v1/document/list?pageNo=1&pageSize=100&parentId=${key}`);
  }
  const { run: getDocList } = useRequest(requestDocList, {
    manual: true,
  });

  const initMenu = async () => {
    const result = await getDirectoryList();
    setDirectoryList(result?.data?.data);

    if (result?.data?.data[0]?.childrenDocument.length > 0) {
      const result2 = await getDocList(result?.data?.data[0]?.id);
      setDocList(result2?.data?.data?.list);
      setSelectedKey(result2?.data?.data?.list[0]?.id);
      showSelectedDoc(result2?.data?.data?.list[0]?.id); //默认显示第一篇文档
      setCurDocIdx(0);
      setMaxDocIdx(result2?.data?.data?.list.length);
    } else {
      if (result?.data?.data[0]?.childrenCatalog.length > 0) {
        const result2 = await getDocList(result?.data?.data[0]?.childrenCatalog[0]?.id);
        setDocList(result2?.data?.data?.list);
        setSelectedKey(result2?.data?.data?.list[0]?.id);
        showSelectedDoc(result2?.data?.data?.list[0]?.id); //默认显示第一篇文档
        setCurDocIdx(0);
        setMaxDocIdx(result2?.data?.data?.list.length);
      }
    }
    
  }

  useEffect(() => {
    initMenu();
  }, []);

  const handleClick = async (parentId, id, index) => {
    const result = await getDocList(parentId);
    setDocList(result?.data?.data?.list);
    setSelectedKey(id);
    showSelectedDoc(id);
    setCurDocIdx(index);
    setMaxDocIdx(result?.data?.data?.list.length);
    setIsDrawerVisible(false);
  }

  function requestSelectedDoc(key) {
    return axios.get(`/v1/document/${key}`);
  }

  const { run: getSelectedDoc } = useRequest(requestSelectedDoc, {
    manual: true,
  });

  const showSelectedDoc = async (key) => {
    const result = await getSelectedDoc(key);
    setSelectedDocTitle(result?.data?.data?.title);
    setSelectedDoc(result?.data?.data?.content);
  };

  useEffect(() => {
    window.scrollTo(0, 0); //切换页面时，回到页面顶部
  }, []);

  const handleClickPreBtn = () => {
    const preKey = docList[curDocIdx - 1]?.id;
    setCurDocIdx(curDocIdx - 1);
    setSelectedKey(preKey);
    showSelectedDoc(preKey);
    window.scrollTo(0, 0);
  };

  const handleClickNextBtn = () => {
    const nextKey = docList[curDocIdx + 1]?.id;
    setCurDocIdx(curDocIdx + 1);
    setSelectedKey(nextKey);
    showSelectedDoc(nextKey);
    window.scrollTo(0, 0);
  };

  const pcDocument = (
    <div className={styles.pcDocument}>
      <div className={styles.topBg}>
      </div>

      <div className={styles.body}>
        <div className={styles.bodyContainer}>
          <div className={styles.leftBody}>
            <div className={styles.menuTitle}>信证链文档</div>
            <div className={styles.menu}>
              <Menu mode='inline' selectedKeys={String(selectedKey)}>
                {Array.isArray(directoryList) && directoryList.map((item1) => (
                  <SubMenu
                    title={item1.title}
                  >
                    {item1.childrenDocument.map((item2d, index2) => (
                      <Menu.Item
                        className={styles.menuItems}
                        key={item2d.id}
                        onClick={() => handleClick(item2d.parentId, item2d.id, index2)}
                      >
                        {item2d.title}
                      </Menu.Item>
                    ))}
                    {item1.childrenCatalog.map((item2) => (
                      <SubMenu
                        title={item2.title}
                      >
                        {item2.childrenDocument.map((item3d, index3) => (
                          <Menu.Item
                            className={styles.menuItems}
                            key={item3d.id}
                            onClick={() => handleClick(item3d.parentId, item3d.id, index3)}
                          >
                            {item3d.title}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ))}
                  </SubMenu>
                ))}
              </Menu>
            </div>
          </div>
          <div className={styles.rightBody}>
            <div dangerouslySetInnerHTML={{ __html: selectedDoc }} />
            <div className={styles.rightBtnsContainer}>
              <div className={styles.preBtn}>
                {curDocIdx === 0 ? (
                  <Button disabled={true}>{"< 没有了"}</Button>
                ) : (
                  <Button onClick={handleClickPreBtn}>{"< 上一篇"}</Button>
                )}
              </div>
              <div className={styles.nextBtn}>
                {curDocIdx === maxDocIdx - 1 || maxDocIdx === 0 ? (
                  <Button disabled={true}>{"没有了 >"}</Button>
                ) : (
                  <Button onClick={handleClickNextBtn}>{"下一篇 >"}</Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const phoneDocument = (
    <div className={styles.phoneDocument}>
      <div className={styles.phoneTop}>
        <div className={styles.phoneTopImgContainer}>
          <img
            className={styles.phoneTopImg}
            src={phoneTopImg}
            width={window.innerWidth}
            alt=""
          />
        </div>
        <div
          className={styles.phoneTopDrawerBtn}
          onClick={() => setIsDrawerVisible(true)}
        >
          <MenuOutlined style={{ width: "16px" }} />
        </div>
      </div>

      <div className={styles.phoneDocumentContent}>
        <div className={styles.selectedDocTitle}>{selectedDocTitle}</div>
        <div dangerouslySetInnerHTML={{ __html: selectedDoc }} />
        <div className={styles.phoneDocumentBtnsContainer}>
          <div className={styles.phoneDocumentPreBtn}>
            {curDocIdx === 0 ? (
              <Button disabled={true}>{"< 没有了"}</Button>
            ) : (
              <Button onClick={handleClickPreBtn}>{"< 上一篇"}</Button>
            )}
          </div>
          <div className={styles.phoneDocumentNextBtn}>
            {curDocIdx === maxDocIdx - 1 || maxDocIdx === 0 ? (
              <Button disabled={true}>{"没有了 >"}</Button>
            ) : (
              <Button onClick={handleClickNextBtn}>{"下一篇 >"}</Button>
            )}
          </div>
        </div>
      </div>
      <Drawer
        visible={isDrawerVisible}
        title={" "}
        placement="left"
        onClose={() => setIsDrawerVisible(false)}
        className={styles.phoneDocumentDrawer}
      >
        <div className={styles.phoneDocumentDrawerMenu}>
          <Menu mode="vertical" selectedKeys={String(selectedKey)}>
            {Array.isArray(directoryList) && directoryList.map((item1) => (
              <SubMenu
                title={item1.title}
              >
                {item1.childrenDocument.map((item2d, index2) => (
                  <Menu.Item
                    className={styles.menuItems}
                    key={item2d.id}
                    onClick={() => handleClick(item2d.parentId, item2d.id, index2)}
                  >
                    {item2d.title}
                  </Menu.Item>
                ))}
                {item1.childrenCatalog.map((item2) => (
                  <SubMenu
                    title={item2.title}
                  >
                    {item2.childrenDocument.map((item3d, index3) => (
                      <Menu.Item
                        className={styles.menuItems}
                        key={item3d.id}
                        onClick={() => handleClick(item3d.parentId, item3d.id, index3)}
                      >
                        {item3d.title}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </div>
      </Drawer>
    </div>
  );

  return (
    <>
      {pcDocument}
      {phoneDocument}
    </>
  );
}
