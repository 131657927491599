import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { Radio } from 'antd';
import topImgMobile from "../../../assets/newImg/contactImg/topImgMobile@2x.png";
import styles from "./index.module.less";

const Index = () => {
  const location = useLocation();
  const [contact, setContact] = useState({t1:{},t2:{}});

  useEffect(() => {
    // 当路由切换时 滚动到页面顶部
    window.scrollTo(0, 0);
  }, [location]);
  function requestCode(type) {
    axios.get(
      '/v1/Contact/contactList',
      {params:{type}},
    ).then(({ data }) => {
      if (data.data && data.code === 200) {
        contact[`t${type}`] = data.data;
        setContact({...contact});
      }
    });
  }

  useEffect(() => {
    requestCode(1);
    requestCode(2);
  }, []);

  const [contactType, setContactType] = useState(0);

  return (
    <>
      <div className={styles.topBg}>
        {window.innerWidth <= 765 ? (
          <img
            className={styles.topImg}
            src={topImgMobile}
            width={window.innerWidth}
            alt=""
          />
        ) : (
          <div className={styles.topImgbox}></div>
          // <img className={styles.topImg} src={topImg} width={1440} alt="" />
        )}
      </div>
      <div className={styles.temp}>
        <Radio.Group
          size='large'
          defaultValue={0}
          onChange={(e) =>
            setContactType(e.target.value)
          }
          buttonStyle="solid"
        >
          <Radio.Button value={0}>&nbsp;&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;解&nbsp;&nbsp;&nbsp;&nbsp;</Radio.Button>
          {/* <Radio.Button value={1}>&nbsp;&nbsp;信元数藏&nbsp;&nbsp;</Radio.Button> */}
          <Radio.Button value={2}>可信执法仪</Radio.Button>
        </Radio.Group>
      </div>
      <div className={styles.qrCodeBlockBox}>
        {contactType === 0 && (
          <div className={styles.qrCodeBlock}>
            <img src={contact.t2.url} width={150} alt="" />
            <div className={styles.desc1}>扫一扫添加企业微信</div>
            <div className={styles.desc1}>商务邮箱 {contact.t2.email}</div>
          </div>
        )}
        {/* {contactType === 1 && (
          <div className={styles.qrCodeBlock}>
            <img src={SYPWeChatImg} width={150} alt="" />
            <div className={styles.desc1}>扫一扫添加企业微信</div>
            <div className={styles.desc1}>商务邮箱 songyiping@echaincity.com</div>
          </div>
        )} */}
        {contactType === 2 && (
          <div className={styles.qrCodeBlock}>
            <img src={contact.t1.url} width={150} alt="" />
            <div className={styles.desc1}>扫一扫添加企业微信</div>
            <div className={styles.desc1}>商务邮箱 {contact.t1.email}</div>
          </div>
        )}
      </div>
    </>
  );
};
export default Index;
