import styles from "./index.module.less";

export default function Index(props) {
  return (
    <div className={styles.card}>
      <div
        className={styles.service}
        style={{ backgroundImage: `url(${props.img})` }}
      >
        <div className={styles.serviceName}>{props.name}</div>
      </div>
      <div className={styles.infomation}>
        <div className={styles.content}>{props.infomation}</div>
      </div>
    </div>
  );
}
