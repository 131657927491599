import React, { useState, useEffect } from "react";
import classNames from "classnames";
import CryptoJS from "crypto-js";
import { Button, Descriptions, Spin, Typography, message } from "antd";
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { useLocation } from "react-router";
import Footer from "../../components/NewComponents/Footer";
import Header from "../../components/NewComponents/Header";
import SideButton from "../../components/NewComponents/SideButton";
import CertFileFail from "./components/CertFileFail";
import CertFileInspect from "./components/CertFileForm";
import { arrayBufferToWordArray, progressiveRead } from "../../utils/hashTools";
import { downloadList } from "./const";
import styles from "./index.module.less";

const { Paragraph } = Typography;

export default function Index() {
  const [show, setShow] = useState(2);
  const [loading, setLoading] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [fileName, setFileName] = useState();
  const [checkRes, setCheckRes] = useState();
  const [time, setTime] = useState("");
  const [idCode, setIdCode] = useState("");
  const location = useLocation();

  useEffect(() => {
    onCertBack();
    window.scrollTo(0, 0);
  }, [location]);

  const onFileSubmit = (fileObj) => {
    setLoading(true);
    setShow(2);
    const { file, name } = fileObj;
    formatHash(file);
    setFileName(name);
  };

  const checkHash = async (value) => {
    try {
      const { data: timeData } = await axios.get(`/v1/verify/getServerTime`);
      if (timeData.code === 3002) {
        setShowFail(true);
      }
      if (timeData.code !== 200) {
        message.error(timeData?.message);
      }
      const t = dayjs(timeData.data).format("YYYY-MM-DD HH:mm:ss");
      setTime(t);

      const data = {
        code: value ? 200 : 3002,
        type: 3,
      };
      if (data.code === 3002) {
        setShowFail(true);
      }
      setLoading(false);
      setShow(data.type);
      setCheckRes(value);
      const str = CryptoJS.SHA256(value + t + "SHA-256").toString(
        CryptoJS.enc.Hex
      );
      setIdCode(str);
    } catch (e) {
      setLoading(false);
    }
  };

  const formatHash = (file) => {
    const sha = CryptoJS.algo.SHA256.create();
    progressiveRead(
      file,
      function (data) {
        var wordArray = arrayBufferToWordArray(data);
        sha.update(wordArray);
      },
      function (origin) {
        const hash = sha.finalize().toString();
        checkHash(hash);
      }
    );
  };
  const onCertBack = () => {
    setShowFail(false);
    setShow(2);
  };
  const handleClickCopy = () => {
    copy(`文件Hash: ${checkRes}
加密算法: SHA-256
计算时间: ${time}
识别码: ${idCode}`);
    message.success("复制成功");
  };
  return (
    <>
      <Header selectedKeys={["fileInspect"]} />
      <div className={styles.mobile}>
        {/* <h1 className={styles.tit}>哈希计算工具</h1>
        <p className={styles.desc}>
          1.哈希值计算工具同时支持
          {downloadList.map((item) => (
            <>
            <Button
              type="link"
              key={item.label}
              onClick={() => (window.location.href = item.url)}
            >
              {item.label}
            </Button>
            ，
            </>
          ))}
          可支持处理200M 以上的大文件。
          <br />
          2.若您的文件小于200M，可选择浏览器版哈希计算工具（免安装）。
          <br />
          3.离线版、浏览器版哈希计算工具都不会将上传文件及计算结果传输到服务器，请复制计算结果进行存证或查验。
        </p> */}
        {showFail && <CertFileFail onBack={onCertBack} />}
        {!showFail && (
          <>
            {show === 2 && (
              <>
                <h1 className={styles.tit}>哈希计算工具</h1>
                <p className={styles.desc}>
                  1.哈希值计算工具同时支持
                  {downloadList.map((item) => (
                    <>
                      <Button
                        type="link"
                        key={item.label}
                        onClick={() => (window.location.href = item.url)}
                      >
                        {item.label}
                      </Button>
                      ，
                    </>
                  ))}
                  可支持处理200M 以上的大文件。
                  <br />
                  2.若您的文件小于200M，可选择浏览器版哈希计算工具（免安装）。
                  <br />
                  3.离线版、浏览器版哈希计算工具都不会将上传文件及计算结果传输到服务器，请复制计算结果进行存证或查验。
                </p>
                {loading ? (
                  <Spin
                    spinning={true}
                    size="large"
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 60, color: "#335FFF" }}
                        spin
                      />
                    }
                    tip={<div className={styles.loadingTips}>文件计算中，请稍等</div>}
                  />
                ) : (
                  <>
                    <div className={styles.tabCon}>
                      <CertFileInspect loading onSubmit={onFileSubmit} />
                    </div>
                  </>
                )}
              </>
            )}
            {show !== 2 && (
              <div className={styles.fullResult}>
                <div className={classNames(styles.backLink, styles.backCenter)}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={onCertBack}>
                    <ArrowLeftOutlined /> 继续计算
                  </a>
                  <div className={styles.card}>
                    <div className={styles.succTips}>
                      <CheckCircleFilled className={styles.succIcon} />
                      计算成功！
                    </div>
                    <div className="block">
                      <Descriptions
                        className={styles.succDescriptions}
                        column={1}
                      >
                        <Descriptions.Item label="文件Hash">
                          <div style={{ fontWeight: 500, color: "#161640" }}>
                            {checkRes}
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="文件名称">
                          {fileName}
                        </Descriptions.Item>
                        <Descriptions.Item label="加密算法">
                          SHA-256
                        </Descriptions.Item>
                        <Descriptions.Item label="计算时间">
                          {time}
                        </Descriptions.Item>
                        <Descriptions.Item label="识别码">
                          {idCode}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </div>
                  <Button
                    className={styles.copyBtn}
                    type="primary"
                    onClick={handleClickCopy}
                  >
                    复制
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
