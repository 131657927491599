import React from "react";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./index.module.less";

const Index = (props) => {
  const { onBack } = props;
  return (
    <div className={styles.box}>
      <p className={styles.tips}>
        <ExclamationCircleOutlined className={styles.icon} />
        校验失败，该文件未在链上存证，请重新校验！
      </p>
      <Button
        // style={{ width: 132 }}
        className={styles.btn}
        type="primary"
        onClick={() => {
          onBack();
        }}
      >
        重新校验
      </Button>
    </div>
  );
};
export default Index;
