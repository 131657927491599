import React from "react";
import { useLocation } from "react-router-dom";
// import styles from './index.module.less'

// let show = 0
export default function Cert() {
  const location = useLocation();
  const { state } = location;
  return (
    <>
      {state.content ? (
        <div dangerouslySetInnerHTML={{ __html: state.content }} />
      ) : (
        <div style={{ textAlign: "center" }}>
          <img
            style={state.local ? { width: "100%" } : {}}
            src={state.local ? require("../../assets/pdf.jpg") : state.src}
            alt=""
          />
        </div>
      )}
    </>
  );
}
