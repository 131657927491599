import React from "react";
import Footer from "../../components/NewComponents/Footer";
import Header from "../../components/NewComponents/Header";
import Document from "../../components/NewComponents/Document";
import SideButton from "../../components/NewComponents/SideButton";

export default function CertPage() {
  return (
    <>
      <Header selectedKeys={["document"]} />
      <Document />
      <SideButton label="联系我们" url="/contact" />
      <Footer />
    </>
  );
}
