import styles from "./index.module.less";
import { useHistory } from "react-router";
import { Button } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
// import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Scrollbar } from "swiper";
import titleIntroduction from "../../../../assets/newImg/NFT/titleIntroduction@2x.png";

import kxzfyPic1 from "../../../../assets/newImg/appDownloadImg/kxzfyPic1@2x.png";
import kxzfyPic2 from "../../../../assets/newImg/appDownloadImg/kxzfyPic2@2x.png";
import productValue from "../../../../assets/newImg/appDownloadImg/productValue@2x.png";
import digitalPaper from "../../../../assets/newImg/appDownloadImg/digitalPaper@2x.png";
import applicableScene from "../../../../assets/newImg/appDownloadImg/applicableScene@2x.png";
import notarization from "../../../../assets/newImg/appDownloadImg/notarization@2x.png";
import tranportation from "../../../../assets/newImg/appDownloadImg/tranportation@2x.png";
import customhouse from "../../../../assets/newImg/appDownloadImg/customhouse@2x.png";
import fireFighting from "../../../../assets/newImg/appDownloadImg/fireFighting@2x.png";
import safetySupervision from "../../../../assets/newImg/appDownloadImg/safetySupervision@2x.png";
import industryAndCommerce from "../../../../assets/newImg/appDownloadImg/industryAndCommerce@2x.png";
import drugSafety from "../../../../assets/newImg/appDownloadImg/drugSafety@2x.png";
import taxAdministration from "../../../../assets/newImg/appDownloadImg/taxAdministration@2x.png";
import electricPower from "../../../../assets/newImg/appDownloadImg/electricPower@2x.png";
import cityAdministration from "../../../../assets/newImg/appDownloadImg/cityAdministration@2x.png";
import check from "../../../../assets/newImg/appDownloadImg/check.png";

const values = [
  {
    key: "jsgz",
    title: "可信上链，即时固证",
    desc:
      "采用区块链数据加密与防篡改等技术手段及相关设计，保障行政执法仪中的电子数据全流程不被破坏、篡改，保证数据的安全有效。",
  },
  {
    key: "fzfg",
    title: "实时上传，防止篡改",
    desc: "通过 4G/5G/wifi 网络，实现执法仪中的电子数据实时上传。",
  },
  {
    key: "zsyx",
    title: "组网互备，真实有效",
    desc:
      "通过行政执法仪之间组网互备，保障在网络中断、弱连接等数据无法实时上传的情况下数据仍然安全有效。",
  },
];

const candoList = ['录像', '拍照', '录音', '通讯', '定位', '报警', '数据加密', '固证上链'];

const imgBlocks = [
  {
    pic: notarization,
    caption: "公证",
  },
  {
    pic: tranportation,
    caption: "交通",
  },
  {
    pic: customhouse,
    caption: "海关",
  },
  {
    pic: fireFighting,
    caption: "消防",
  },
  {
    pic: safetySupervision,
    caption: "安监",
  },
  {
    pic: industryAndCommerce,
    caption: "工商",
  },
  {
    pic: drugSafety,
    caption: "药监",
  },
  {
    pic: taxAdministration,
    caption: "税务",
  },
  {
    pic: electricPower,
    caption: "电力",
  },
  {
    pic: cityAdministration,
    caption: "城管",
  },
];

export default function Index() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const history = useHistory();
  SwiperCore.use([Navigation, Scrollbar]);

  return (
    <>
      <div className={styles.topBg}>
        <div className={styles.Pic1}>
          <img className={styles.Pic1Img} src={kxzfyPic1} />
            <div className={styles.Cando}>
              {candoList.map((v) => <div className={styles.CandoItem}>
                <img className={styles.CandoImg} src={check} />
                {v}
              </div>)}
            </div>
        </div>
        <div className={styles.Pic2}>
          <img src={kxzfyPic2} />
        </div>
      </div>

      <div className={styles.knowMore}>
        <Button
          className={styles.knowMoreBtn}
          onClick={() => { history.push("/contact") }}
        >
          了解更多
        </Button>
      </div>
      <div className={styles.productValue}>
        <img src={productValue} className={styles.titleImg} alt="" />
        <div className={styles.productValueContent}>
          {values.map((item) => {
            return (
              <div className={styles.value} key={item.key}>
                <div className={styles.valueTitle}>
                  <div className={styles.blueBlock}></div>
                  {item.title}
                </div>
                <div className={styles.valueDesc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.productIntroduction}>
        <div className={styles.productIntroductionBox}>
          <img src={titleIntroduction} className={styles.titleImg} alt="" />
          <div className={styles.productIntroductionContent}>
            <div className={styles.productIntroductionContentLeft}>
              <div className={styles.introduction}>
                可信执法仪是全国性运营级公信联盟区块链“信证链”的智能执法终端设备。通过可信执法仪为用户提供基于区块链技术的现场取证固证活动支撑，从源头上保障电子证据无法篡改、真实可信。
              </div>
              <div className={styles.contact}>
                <Button
                  className={styles.contactBtn}
                  onClick={() => history.push("/contact")}
                >
                  产品咨询
                </Button>
              </div>
            </div>
            <div className={styles.productIntroductionContentRight}>
              <img src={digitalPaper} style={{ width: "375px" }} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sectionContent2}>
        <div className={styles.applicationField}>
          <img src={applicableScene} className={styles.titleImg} alt="" />
        </div>
        <div className={styles.outWrapper}>
          {imgBlocks.map((item) => {
            return (
              <div className={styles.imgWrapper}>
                <img src={item.pic} />
                <div className={styles.caption}>{item.caption}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
