import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRequest } from "ahooks";
import { Pagination } from "antd";
import { provinceMap } from "../mapData";
import styles from "./index.module.less";

const pageSize = 7;
const getKnotsList = ({ pageNo = 1, areaName }) => {
  return axios.get(
    `v1/map/list?knotName=${areaName}&pageNo=${pageNo}&pageSize=${pageSize}`
  );
};

const Index = ({ areaName }) => {
  const [province, setProvice] = useState();
  const {
    run: runKnotsList,
    data: kontsList = { currentPage: 1, totalCount: 0 },
  } = useRequest(getKnotsList, {
    formatResult: (res) => {
      const result = res?.data?.data;
      if (result) return result;
      return {};
    },
  });

  useEffect(() => {
    if (areaName) {
      const area = provinceMap[areaName];
      setProvice(area);
      runKnotsList({ areaName: area });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaName]);

  const onPaginationChange = (params) => {
    runKnotsList({ areaName: province, pageNo: params });
  };

  return (
    <>
      <div className={styles.knotsTitle}>{province ? province : "中国"}</div>
      <div className={styles.knotsList}>
        {kontsList?.list?.map((item) => {
          return <div key={item.id}>{item.name}</div>;
        })}
      </div>
      <Pagination
        className={styles.knotsPagination}
        simple
        pageSize={pageSize}
        current={kontsList?.currentPage}
        total={kontsList?.totalCount || 1}
        onChange={onPaginationChange}
      />
    </>
  );
};
export default Index;
