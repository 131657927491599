import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { Menu, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import logoBlue from "../../../assets/newImg/commonImg/xzlLogoBlue.svg";
import logoWhite from "../../../assets/newImg/commonImg/xzlLogoWhite.svg";
import SubMenu from "antd/lib/menu/SubMenu";

const menuItems = [
  {
    key: "home",
    label: "首页",
  },
  {
    key: "service",
    label: "产品服务",
    type: 'submenu'
  },
  {
    key: "certs",
    label: "信证查验",
    type: 'submenu'
  },
  {
    key: "browser",
    label: "浏览器",
    type: 'submenu'
  },
  {
    key: "news",
    label: "资讯动态",
  },
  {
    key: "document",
    label: "文档中心",
  },
  {
    key: "about",
    label: "关于我们",
  },
];



const serviceSubMenuItems = [
  {
    key: "assets",
    label: "数据资产存证公证平台",
    action: () => { },
  },
  {
    key: "zjapp",
    label: "证解",
    action: () => { },
  },
  {
    key: "kxzfy",
    label: "可信执法仪",
    action: () => { },
  },
];


export default function Index(props) {
  const location = useLocation();
  const history = useHistory();
  const [isLogoBlue, setIsLogoBlue] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    const nameList = ["/zjapp", "/assets", "/kxzfy", "/cert", "/nftcert", "/fileInspect"]
    if (nameList.some(item => item === location.pathname)) {
      setIsLogoBlue(true);
    } else {
      setIsLogoBlue(false);
    }
  }, [location]);

  const goTo = (key) => {
    // if (key === "zj") {
    //   const newPage = window.open("about:blank");
    //   newPage.location.href = "https://evidence.wetrustchain.com/user/login";
    // } else
    if (key === "home") {
      history.push("/");
    } else {
      history.push("/" + key);
    }
    setIsDrawerVisible(false);
  };

  const certSubMenuItems = [
    {
      key: 'cert',
      label: '证据查验',
      action: () => goTo('check'),
    },
    {
      key: 'nftcert',
      label: '藏品查验',
      action: () => goTo('nftcert'),
    },
    {
      key: 'fileInspect',
      label: '哈希计算工具',
      action: () => goTo('fileInspect'),
    }
  ]

  const browserSubMenuItems = [
    {
      key: 'blockchain',
      label: '区块链浏览器',
      useAction: true,
      action: () => {
        window.location.href = 'https://browser.wetrustchain.com/'
      },
    },
    {
      key: 'full',
      label: '数据大屏',
      useAction: true,
      action: () => {
        // requestFullScreen();
        window.location.href = 'https://browser.wetrustchain.com/datascreen?type=1';
      },
    },
  ]

  const submenuItems = {
    service: serviceSubMenuItems,
    certs: certSubMenuItems,
    browser: browserSubMenuItems
  }

  const requestFullScreen = () => {
    const de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen();
    } else if (de.webkitRequestFullScreen) {
      de.webkitRequestFullScreen();
    }
  };
  const handleClickLogo = () => {
    history.push("/");
  };

  const menuStyle = {
    color: isLogoBlue ? "rgba(22,22,64,0.65)" : "rgba(255,255,255,0.80)",
    fontSize: "14px",
    background: "none",
    border: "none",
    fontFamily: "PingFangSC, PingFangSC-Regular",
    fontWeight: "400",
  };

  const menuItemStyle = {
    color: "rgba(22, 22, 64, 0.87)",
    fontSize: "14px",
    background: "rgba(255, 255, 255, 0.1)",
    border: "none",
    fontFamily: "PingFang SC, PingFangSC-Regular",
    fontWeight: "400",
  };

  const drawerTitle = (
    <div>
      <img
        src={logoBlue}
        alt=""
        style={{ height: "20px", marginLeft: "5px" }}
      />
    </div>
  );

  const phoneHeader = (
    <div className={styles.phoneHeaderContainer}>
      <div className={styles.phoneHeader}>
        <img className={styles.phoneHeaderLogo} src={logoBlue} alt="" />
        <MenuOutlined
          className={styles.phoneHeaderBtn}
          onClick={() => setIsDrawerVisible(true)}
        />
      </div>
      <Drawer
        visible={isDrawerVisible}
        title={drawerTitle}
        height="100%"
        placement="top"
        onClose={() => setIsDrawerVisible(false)}
      >
        <div className={styles.drawerMenu}>
          <Menu
            mode="inline"
            className={styles.phoneHeaderMenu}
          // defaultOpenKeys={["certs","service"]}
          >

            {menuItems.map((item) => {
              if (item.type === 'submenu') {
                return (
                  <SubMenu
                    popupClassName={styles.subMenuBgColor}
                    key={item.key}
                    title={item.label}
                  >
                    {submenuItems[item.key].map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={item.action}
                        >
                          {item.icon && <img className={styles.subMenuIcon} src={item.icon} alt='' />}
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item key={item.key} onClick={() => goTo(item.key)}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </Drawer>
    </div>
  );

  const pcHeader = (
    <div className={styles.header}>
      <div className={styles.sectionHeader}>
        <img
          className={styles.logo}
          src={isLogoBlue ? logoBlue : logoWhite}
          alt="信证链logo"
          onClick={handleClickLogo}
        />
        <div
          className={`${styles.headerMenu} ${isLogoBlue ? styles.headerMenuBlue : styles.headerMenuWhite
            }`}
        >
          <Menu
            mode="horizontal"
            selectedKeys={props.selectedKeys}
            style={menuStyle}
          >
            {menuItems.map((item) => {
              if (item.type === 'submenu') {
                return (
                  <SubMenu
                    popupClassName={styles.subMenuBgColor}
                    key={item.key}
                    title={item.label + ' ▾'}
                  >
                    {submenuItems[item.key].map((item) => {
                      return (
                        <Menu.Item
                          key={item.key}
                          onClick={() => {
                            if (item.useAction) {
                              item.action();
                            } else {
                              goTo(item.key)
                            }
                          }}
                        >
                          {item.icon && <img className={styles.subMenuIcon} src={item.icon} alt='' />}
                          {item.label}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
              return (
                <Menu.Item
                  key={item.key}
                  onClick={() => goTo(item.key)}
                >
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {pcHeader}
      {phoneHeader}
    </>
  );
}
