import React, { useState, useRef, useEffect, useMemo } from "react";
import classNames from "classnames";
import CryptoJS from "crypto-js";
import { Input, Descriptions, message, Image, Spin } from "antd";
import {
  CheckCircleFilled,
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  CertType,
  hideShow3Ways,
  DataIntellectualPropertyCertId,
  EEvidenceTypeMap,
} from "./const";
import CertFileFail from "./components/CertFileFail";
import CertFileInspect from "./components/CertFileForm";
import {
  arrayBufferToWordArray,
  progressiveRead,
} from "../../../utils/hashTools";
import styles from "./index.module.less";

export default function Index() {
  const [show, setShow] = useState(2);
  const [loading, setLoading] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [fileName, setFileName] = useState();
  const [currentTab, setCurrentTab] = useState("CODECERT");
  const [checkRes, setCheckRes] = useState({ userPhone: "" });
  const [checkResContent, setCheckResContent] = useState({ userPhone: "" });
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.search.indexOf("firstSixHash") > 0) {
      setInputValue(location.search.slice(-6));
      checkHash(location.search.slice(-6), "CODECERT");
    }
  }, [location]);

  useEffect(() => {
    onCertBack();
    setCurrentTab("CODECERT");
    window.scrollTo(0, 0);
  }, [location]);

  const handleCodeSubmit = () => {
    const value = inputRef.current.state.value;
    if (value) {
      checkHash(value.trim(), "CODECERT");
    } else {
      message.error("请输入证据哈希或证据短哈希（证据哈希前六位）");
    }
  };

  const onFileSubmit = (fileObj) => {
    setLoading(true);
    const { file, name } = fileObj;
    formatHash(file);
    setFileName(name);
  };

  const checkHash = async (value, tab) => {
    try {
      const { data } = await axios.get(`/v1/verify/cert?firstSixHash=${value}`);
      if (data.code === 3002 && tab === "FILECERT") {
        setShowFail(true);
      } else if (data.code !== 200) {
        message.error(data?.message);
        throw new Error(data.message);
      }
      setLoading(false);
      setShow(data.data.type);
      setCheckRes(data.data);
      if (data.data.type === 3 || 4) {
        const contentObj = JSON.parse(data.data.content);
        setCheckResContent(contentObj);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const formatHash = (file) => {
    const sha = CryptoJS.algo.SHA256.create();
    progressiveRead(
      file,
      function (data) {
        var wordArray = arrayBufferToWordArray(data);
        sha.update(wordArray);
      },
      function (origin) {
        checkHash(sha.finalize().toString(), "FILECERT");
      }
    );
  };

  const onCertBack = () => {
    setShowFail(false);
    setShow(2);
  };
  const dtos = useMemo(() => checkResContent?.requestWebDtos || [], [
    checkResContent.requestWebDtos,
  ]);
  return (
    <div className={styles.mobile}>
      {showFail && <CertFileFail onBack={onCertBack} />}
      {!showFail && (
        <>
          {show === 2 &&
            (loading ? (
              <Spin
                spinning={true}
                size="large"
                indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
                tip="文件校验中, 请稍等..."
              />
            ) : (
              <>
                <div className={styles.tab}>
                  {Object.keys(CertType).map((val) => {
                    return (
                      <span
                        key={val}
                        className={classNames(
                          currentTab === val && styles.active
                        )}
                        onClick={() => {
                          setCurrentTab(val);
                        }}
                      >
                        {CertType[val]}
                      </span>
                    );
                  })}
                </div>
                <div className={styles.tabCon}>
                  {currentTab === "CODECERT" && (
                    <div className={styles.formWrap}>
                      <Input
                        placeholder="请输入证据哈希或证据短哈希（证据哈希前六位）"
                        ref={inputRef}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onPressEnter={handleCodeSubmit}
                        addonAfter={
                          <a onClick={handleCodeSubmit}>
                            <SearchOutlined style={{ color: "#161640" }} />
                          </a>
                        }
                      />
                      <div className={styles.illustration}>
                        <div>查验说明：</div>
                        <div>
                          1、信证查验支持证解、信监保、信证通和可信执法仪等信证链产品的上链数据的查验。
                        </div>
                        <div>
                          2、哈希查验需输入完整的证据哈希或者证据短哈希（证据哈希前六位）进行查验。
                        </div>
                        <div>
                          3、文件校验需上传完整的证据包进行校验。校验信监保证据包时需解压证据总包，再使用总包文件夹中的证据包文件进行校验。
                        </div>
                      </div>
                    </div>
                  )}

                  {currentTab === "FILECERT" && (
                    <CertFileInspect loading onSubmit={onFileSubmit} />
                  )}
                </div>
              </>
            ))}
          {currentTab === "FILECERT" && show !== 2 && show !== 5 && (
            <div className={styles.fullResult}>
              <div className={classNames(styles.backLink, styles.backCenter)}>
                <a onClick={onCertBack}>
                  <ArrowLeftOutlined /> 继续查验
                </a>
                <div className={styles.card}>
                  <div className={styles.succTips}>
                    <CheckCircleFilled className={styles.succIcon} />
                    校验成功！
                  </div>
                  <div className="block">
                    <div className="title">保全信息</div>
                    <Descriptions column={1}>
                      <Descriptions.Item label="保全人">
                        {checkResContent.savePerson}
                      </Descriptions.Item>
                      <Descriptions.Item label="保全时间">
                        {checkResContent.saveTime}
                      </Descriptions.Item>
                      <Descriptions.Item label="证据ID">
                        {checkResContent.requestId}
                      </Descriptions.Item>
                      <Descriptions.Item label="存证hash">
                        {checkRes.contentHash}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="block">
                    <div className="title">区块信息</div>
                    <Descriptions column={1}>
                      <Descriptions.Item label="区块号">
                        {checkRes.blockNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="所属区块链">
                        {checkRes.chainName}
                      </Descriptions.Item>
                      <Descriptions.Item label="区块hash">
                        {checkRes.blockHash}
                      </Descriptions.Item>
                      <Descriptions.Item label="交易hash">
                        {checkRes.txHash}
                      </Descriptions.Item>
                      {DataIntellectualPropertyCertId ===
                        checkResContent.way && (
                        <Descriptions.Item label="识别码">
                          {checkResContent.identificationCode}
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentTab === "CODECERT" && (
            <>
              {show === 1 && (
                <div className={styles.fullResult}>
                  <div
                    className={classNames(styles.backLink, styles.backCenter)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onCertBack}>
                      <ArrowLeftOutlined /> 继续查验
                    </a>
                    <div className={styles.card}>
                      <div className="block">
                        <div className="title">个人信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="姓名">
                            {checkRes.userName}
                          </Descriptions.Item>
                          <Descriptions.Item label="手机号码">
                            {checkRes.userPhone.replace(
                              /^(\d{3})\d{4}(\d{4})$/,
                              "$1****$2"
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="block">
                        <div className="title">区块信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="区块号">
                            {checkRes.blockNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="所属区块链">
                            {checkRes.chainName}
                          </Descriptions.Item>
                          <Descriptions.Item label="区块Hash">
                            {checkRes.blockHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="交易哈希">
                            {checkRes.txHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="证书Hash">
                            {checkRes.contentHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="上链时间">
                            {checkRes.time}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className="block">
                        <div className="title">证书信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="证书名称">
                            {checkRes.certName}
                          </Descriptions.Item>
                          <Descriptions.Item label="活动名称">
                            {checkRes.activityName}
                          </Descriptions.Item>
                          <Descriptions.Item label="活动日期">
                            {checkRes.activityStartDate}
                          </Descriptions.Item>
                          <Descriptions.Item label="活动地点">
                            {checkRes.address}
                          </Descriptions.Item>
                          <Descriptions.Item label="主办方">
                            {checkRes.organizer}
                          </Descriptions.Item>
                          <Descriptions.Item label="发证方">
                            {checkRes.issuer}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {show === 0 && (
                <div className={styles.briefResult}>
                  <div
                    className={classNames(styles.backLink, styles.backCenter)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onCertBack}>
                      <ArrowLeftOutlined /> 继续查验
                    </a>

                    <div className={styles.card}>
                      <div className="block" style={{ margin: "0px auto" }}>
                        <div className="title">上链信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="区块高度">
                            {checkRes.blockNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="Hash码">
                            {checkRes.txHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="案例编号">
                            {checkRes.caseNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="案例名称">
                            {checkRes.caseName}
                          </Descriptions.Item>
                          <Descriptions.Item label="上链时间">
                            {checkRes.time}
                          </Descriptions.Item>
                          <Descriptions.Item label="关联单位">
                            {checkRes.relatedOrg}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                      <div className={styles.lookUpSummary}>
                        {checkRes.content ? (
                          <Link
                            to={{
                              pathname: `/certsummary`,
                              state: {
                                local: true,
                                content: checkRes.content,
                              },
                            }}
                            className="sum sum-mobile"
                            style={{ margin: "262px auto 0" }}
                          >
                            点击查看案例概述
                          </Link>
                        ) : (
                          <Link
                            to={{
                              pathname: `/certsummary`,
                              state: {
                                local: true,
                                content: false,
                              },
                            }}
                            className="sum sum-mobile"
                            style={{ margin: "-600px auto 0" }}
                          >
                            点击查看案例概述
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {show === 3 && (
                <div className={styles.resultWithCertPdf}>
                  <div
                    className={classNames(styles.backLink, styles.backCenter)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onCertBack}>
                      <ArrowLeftOutlined /> 继续查验
                    </a>

                    <div className={styles.card}>
                      {!hideShow3Ways.includes(checkResContent.way) && (
                        <div className="block">
                          <div className="title">取证信息</div>
                          <Descriptions column={1}>
                            {/* <Descriptions.Item label="取证ID">
                            {checkResContent.requestId}
                          </Descriptions.Item> */}
                            <Descriptions.Item label="取证人">
                              {checkResContent.person}
                            </Descriptions.Item>
                            <Descriptions.Item label="取证时间">
                              {checkResContent.createTime}
                            </Descriptions.Item>
                            {/* {checkResContent.way !== 0 ? (
                            <> */}
                            {/* <Descriptions.Item label="取证时长">{checkResContent.evidenceTime}秒</Descriptions.Item> */}
                            {/* <Descriptions.Item label="地理位置">{checkResContent.location}</Descriptions.Item> */}
                            {/* </>
                          ) : (
                            <Descriptions.Item label="取证网址">
                              {checkResContent.url}
                            </Descriptions.Item>
                          )} */}
                          </Descriptions>
                        </div>
                      )}

                      <div className="block">
                        <div className="title">保全信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="保全人">
                            {checkResContent.savePerson}
                          </Descriptions.Item>
                          <Descriptions.Item label="保全时间">
                            {checkResContent.saveTime}
                          </Descriptions.Item>
                          <Descriptions.Item label="证据ID">
                            {checkResContent.requestId}
                          </Descriptions.Item>
                          <Descriptions.Item label="存证hash">
                            {checkRes.contentHash}
                          </Descriptions.Item>
                          {/* {checkResContent.orgName && (
                            <Descriptions.Item label="保全机构">
                              {checkResContent.orgName}
                            </Descriptions.Item>
                          )} */}
                        </Descriptions>
                      </div>

                      <div className="block">
                        <div className="title">区块信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="区块号">
                            {checkRes.blockNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="所属区块链">
                            {checkRes.chainName}
                          </Descriptions.Item>
                          <Descriptions.Item label="区块hash">
                            {checkRes.blockHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="交易hash">
                            {checkRes.txHash}
                          </Descriptions.Item>
                          {DataIntellectualPropertyCertId ===
                            checkResContent.way && (
                            <Descriptions.Item label="识别码">
                              {checkResContent.identificationCode}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </div>

                      {/* <div className="block">
                        <div className="title">保全证书</div>
                        <Image src={checkRes.certUrl} className={styles.certImg} />
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
              {show === 4 && (
                <div className={styles.resultWithCertPdf}>
                  <div
                    className={classNames(styles.backLink, styles.backCenter)}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={onCertBack}>
                      <ArrowLeftOutlined /> 继续查验
                    </a>

                    <div className={styles.card}>
                      <div className="block">
                        <div className="title">证据信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="证据ID">
                            {checkResContent.requestId}
                          </Descriptions.Item>
                          <Descriptions.Item label="证据名称">
                            {checkResContent.evidenceName}
                          </Descriptions.Item>
                          <Descriptions.Item label="取证人">
                            {checkResContent.person}
                          </Descriptions.Item>
                          <Descriptions.Item label="设备ID">
                            {checkResContent.deviceId}
                          </Descriptions.Item>
                          <Descriptions.Item label="取证时间">
                            {checkResContent.createTime}
                          </Descriptions.Item>
                          <Descriptions.Item label="上链时间">
                            {checkRes.chainTime}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>

                      <div className="block">
                        <div className="title">区块信息</div>
                        <Descriptions column={1}>
                          <Descriptions.Item label="所属区块">
                            {checkRes.blockNumber}
                          </Descriptions.Item>
                          <Descriptions.Item label="所属区块链">
                            {checkRes.chainName}
                          </Descriptions.Item>
                          <Descriptions.Item label="证据哈希">
                            {checkRes.certHash}
                          </Descriptions.Item>
                          <Descriptions.Item label="交易哈希">
                            {checkRes.blockHash}
                          </Descriptions.Item>
                        </Descriptions>
                      </div>

                      <div className="block">
                        <div className="title">保全证书</div>
                        <Image
                          src={checkRes.certUrl}
                          className={styles.certImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {show === 5 && (
            <div className={styles.fullResult}>
              <div className={classNames(styles.backLink, styles.backCenter)}>
                <a onClick={onCertBack}>
                  <ArrowLeftOutlined /> 继续查验
                </a>
                <div className={styles.card}>
                  <div className={styles.succTips}>
                    <CheckCircleFilled className={styles.succIcon} />
                    校验成功！
                  </div>
                  <div className="block">
                    <div className="title">证据信息</div>
                    <Descriptions column={1}>
                      <Descriptions.Item label="证据ID">
                        {checkResContent.requestId}
                      </Descriptions.Item>
                      <Descriptions.Item label="取证时间">
                        {checkResContent.createTime}
                      </Descriptions.Item>
                      <Descriptions.Item label="取证人">
                        {checkResContent.person}
                      </Descriptions.Item>
                      <Descriptions.Item label="取证平台">
                        信监保
                      </Descriptions.Item>
                      <Descriptions.Item label="取证类型">
                        {EEvidenceTypeMap[checkResContent.way]}
                      </Descriptions.Item>
                      {checkResContent.location && (
                        <Descriptions.Item label="地理位置">
                          {checkResContent.location}
                        </Descriptions.Item>
                      )}
                      {checkResContent.requestWebDtos &&
                        dtos.map((dto, index) => (
                          <Descriptions.Item
                            key={dto.id}
                            label={`取证地址${index + 1}`}
                          >
                            {dto.url}
                          </Descriptions.Item>
                        ))}
                    </Descriptions>
                  </div>
                  <div className="block">
                    <div className="title">链上信息</div>
                    <Descriptions column={1}>
                      <Descriptions.Item label="区块号">
                        {checkRes.blockNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="所属区块链">
                        {checkRes.chainName}
                      </Descriptions.Item>

                    <Descriptions.Item label="证据包哈希">
                      {checkRes.contentHash}
                      </Descriptions.Item>
                      <Descriptions.Item label="区块链哈希">
                        {checkRes.txHash}
                      </Descriptions.Item>
                      <Descriptions.Item label="上链时间">
                        {checkRes.time}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
