export const CertType = {
  CODECERT: "证据查询",
  FILECERT: "文件校验",
};

export const hideShow3Ways = [30,40,45,50];

/** 数据知识产权存证 id */
export const DataIntellectualPropertyCertId = 45


export const  EEvidenceTypeMap = {
  0: 'WEB网页取证',
  5: 'WEB录屏取证',
  15: 'APP录屏取证',
  20: 'APP现场取证',
  25: 'APP录音取证',
  50: 'WEB数据保全',
  35: 'APP拍照取证',
}

