/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {Descriptions} from 'antd';

import {CheckCircleFilled} from '@ant-design/icons';
import styles from './index.module.less';
import dayjs from 'dayjs';


export default (props) => {

  const items = [
    {
      label: '发送方',
      text: props?.sender
    },
    {
      label: '接收方',
      text: props?.receiver,
    },
    {
      label: '交易时间',
      text: dayjs(props?.txTime).format('YYYY-MM-DD HH:mm'),
    },
    {
      label: '交易哈希',
      text: props?.txHash,
    },
    {
      label: '区块高度',
      text: props?.blockHeight,
    },
    {
      label: '所属区块链',
      text: props?.chainName,
    },
  ];

  return (

    <div className={styles.card}>
      <div className={styles.succTips}>
        <CheckCircleFilled className={styles.succIcon}/>
        校验成功！
      </div>
      <div className={styles.block}>
        <div className={styles.title}>上链信息</div>
        <Descriptions className={styles.succDescriptions} column={1}>
          {
            items.map(item => (
              item.text ? <Descriptions.Item label={item.label}>
                {item.text}
              </Descriptions.Item> : <></>
            ))
          }
        </Descriptions>
      </div>
    </div>
  );
}
