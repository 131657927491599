import React, {useState, useRef, useEffect} from 'react';
import {Input, Select, Descriptions, Image, message, Button} from 'antd';
import {ArrowLeftOutlined, SearchOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router';
import axios from 'axios';
import qs from 'qs';
import classNames from "classnames";
import Pick from './pick';
import { isMobile } from "../../../utils/hashTools";

import img23 from '../../../assets/nftcert/23.png';
import img26 from '../../../assets/nftcert/26.png';
import img27 from '../../../assets/nftcert/27.png';
import img28 from '../../../assets/nftcert/28.png';
import img29 from '../../../assets/nftcert/29.png';
import img30 from '../../../assets/nftcert/30.png';
import img31 from '../../../assets/nftcert/31.png';
import img32 from '../../../assets/nftcert/32.png';
import styles from './index.module.less';
import CheckSuccess from '../CheckSuccess';



const {Option} = Select;
const {Item} = Descriptions;
const HashEnum = {
  COLLECTION: 0,
  TRAD:1,
}
const dataList = [
  {
    name: '阝爰伯铜方鼎',
    id: '23',
    txTime: '2022/04/30 0:32:35',
    txHash: '16ea6b1562d20f0cfeeb21e031cdcb38e286b4f3744c9444212a86aa4531df42',
    shortHash: '16ea6b',
    coverUrl: img23,
    type: '图片',
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '环带纹铜鼎',
    id: '26',
    txTime: '2022/05/01 12:04:22',
    txHash: '16eadf6a30689cd39d9f383adf156b0aa70cd48ef25e7606057dfc282f459494',
    shortHash: '16eadf',
    coverUrl: img26,
    type: '图片',
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '八思巴文虎头银符铁牌',
    id: '27',
    txTime: '2022/05/03 10:23:52',
    txHash: '16eb771754614db60c19352a1d906fd2ffc6507037c08ecbb241b0cbb89d703e',
    shortHash: '16eb77',
    type: '图片',
    coverUrl: img27,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '“都督府”腰牌',
    id: '28',
    txTime: '2022/05/03 23:10:53',
    txHash: '16eba0f282a5636968c8f4a307d3d7849751bf54bb94d5511fb60a5c63be6716',
    shortHash: '16eba0',
    type: '图片',
    coverUrl: img28,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '“晋归义羌侯”金印',
    id: '29',
    txTime: '2022/05/04 17:08:15',
    txHash: '16ebdbbd04dee66700c3a1f6c6efadc0c09b4d9559b1c83efc94ef915a0097b3',
    shortHash: '16ebdb',
    type: '图片',
    coverUrl: img29,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '西夏文“首领”铜印',
    id: '30',
    txTime: '2022/05/05 21:11:23',
    txHash: '16ec37961cd261bd9c909797c791ed6753d548917562b649b9eefbb214906581',
    shortHash: '16ec37',
    type: '图片',
    coverUrl: img30,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '“庄严妙相”象牙印',
    id: '31',
    txTime: '2022/05/06 22:43:59',
    txHash: '16ec8b384745f15f907f8285696053f477ba35d0b0f757176b67e104280c580f',
    shortHash: '16ec8b',
    type: '图片',
    coverUrl: img31,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
  {
    name: '狮钮铜印章',
    id: '32',
    txTime: '2022/05/07 16:48:19',
    txHash: '16ecc6644d6a827c4237f056e6664f52b7b6cec2d59e3a43c2accaec5d89e70b',
    shortHash: '16ecc6',
    type: '图片',
    coverUrl: img32,
    castNum: 3000,
    chainName: '信证链',
    verifyType: HashEnum.COLLECTION,
  },
];



export default function Index() {
  const [resultVisible, setResultVisible] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [checkResult, setCheckResult] = useState();
  const [selectData, setSelectData] = useState([]);

  const location = useLocation();


  useEffect(async () => {
    try {
      const {data} = await axios.get('/v1/nft/platformList');
      if (data.code !== 200) {
        throw new Error(data.message);
      }
      setSelectData(data.data);
    } catch (e) {
      message.error(e.message);
    }

    await checkFromUrlParams();

  }, []);

  const checkFromDatabase = async (hash, platformId) => {
    if (typeof hash === 'undefined' ) return;
    try {
      const {data} = await axios.get(`/v1/nft/nftVerify?${qs.stringify({hash, platformId})}`);
      if (data.code !== 200) {
        throw new Error(data.message);
      }
      setCheckResult(data.data);
      setResultVisible(true);
    } catch (e) {
      message.error(e.message);
    }
  };

  const checkFromUrlParams = async () => {
    const query = new URLSearchParams(location.search);
    const platformId = query.get('platformId');
    const hash = query.get('hash');
    if (!( !!hash)) return;
    await checkFromDatabase(hash, platformId);
  };

  const handleSubmit = async () => {
    const value = inputRef.current.state.value;
    if (!value) {
      message.error("请输入证据哈希或证据短哈希（证据哈希前六位）");
      return;
    }

    const hasFoundInLocal = dataList.reduce((previousValue, collection) => {
      let found = false;
      if (collection.txHash === value || collection.shortHash === value) {
        setCheckResult(() => collection || {});
        setResultVisible(true);
        found = true;
      }
      return previousValue || found;
    }, false);

    if (hasFoundInLocal) {
      return;
    }

    await checkFromDatabase(value, selected);
  };


  // const submit = (value, type) => {
  //   if (type) {
  //     let temp = dataList.filter((item) => {
  //       return item.hash === value
  //     });
  //     if (temp.length) {
  //       temp.map((item) => {
  //         setData({ ...item });
  //         setShow(1);
  //       });
  //     } else {
  //       message.warning('链上无该信息');
  //     }
  //   } else {
  //     let temp = dataList.filter((item) => {
  //       return item.shortHash === value
  //     });
  //     if (temp.length) {
  //       temp.map((item) => {
  //         setData({ ...item });
  //         setShow(1);
  //       });
  //     } else {
  //       message.warning('链上无该信息');
  //     }
  //   }
  // }

  const onCertBack = () => {
    setResultVisible(false);
    setCheckResult(undefined);
    setSelected(undefined);
    setInputValue(undefined);
  };

  const CollectionCheckResult = (
    <div className={styles.card}>
      <div className={styles.left}>
        <Image src={checkResult?.coverUrl} alt="证书"/>
      </div>
      <div className={styles.block}>
        <div className={styles.title}>
          <span>铸造信息</span>
        </div>
        <Descriptions column={1}>
          <Item label="藏品名称">{checkResult?.name}</Item>
          <Item label="发行ID">{checkResult?.id}</Item>
          {/*<Item label="藏品类型">{checkResult?.type}</Item>*/}
          <Item label="铸造数量">{checkResult?.castNum}</Item>
          <Item label="铸造时间">{checkResult?.txTime}</Item>
          <Item label="铸造哈希">{checkResult?.txHash}</Item>
          <Item label="所属区块链">{checkResult?.chainName}</Item>
        </Descriptions>
      </div>
    </div>

  )


  return (
    <div className={classNames({
      [styles.box]: true,
      [styles.boxCheck]: !resultVisible,
      [styles.boxMobile]: resultVisible,
    })}>
      {!resultVisible ? (
        <div className={styles.formWrap}>
          <div className={styles.search}>
            <Input
              placeholder="请输入藏品交易哈希"
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={handleSubmit}
              addonAfter={<a onClick={handleSubmit}><SearchOutlined style={{color: '#161640'}}/></a>}
            />
            <div>
              {
                isMobile() ? (
                  <Pick
                    className={styles.pickGroup}
                    data={selectData}
                    handleConfirm={(platformId) => {
                      setSelected(platformId)
                    }}
                 />
                ) : (
                  <Select
                  defaultValue={undefined}
                  className={styles.selectedGroup}
                  placeholder="请选择数字藏品平台(可选)"
                  onChange={(value) => setSelected(value)}
                >
                    {
                      selectData.map(platForm => (
                        <Option value={platForm.id}>{platForm.name}</Option>
                      ))
                    }
                  </Select>
                )
              }
            </div>

            <Button type="primary" className={styles.checkBtn} onClick={handleSubmit}>开始查验</Button>
          </div>
          <div className={styles.illustration}>
            <div>查验说明：</div>
            <div>
              1、由信证链提供技术支持的数字藏品平台可通过藏品查验上链资产。
            </div>
            <div>
              2、哈希查验需输入完整的证据哈希或者证据短哈希（证据哈希前六位）进行查验。
            </div>
          </div>
        </div>
      ) : (
          <div className={styles.resultWithCertPdf}>
            <div className={classNames(styles.backLink, styles.backCenter)}>
              <a onClick={onCertBack}>
                <ArrowLeftOutlined/> 继续查验
              </a>
              {checkResult?.verifyType === HashEnum.COLLECTION && CollectionCheckResult}
              {checkResult?.verifyType === HashEnum.TRAD && CheckSuccess(checkResult)}
            </div>
          </div>
      )}
    </div>
  );
}
