import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.less";

const PhoneNewsCard = (props) => {
  const { url, key, cover, mainTitle, createTime } = props;

  return (
    <Link target="_blank" to={{ pathname: url }}>
      <div className={styles.phoneNewsContainer} key={key}>
        <div className={styles.phoneNewsLeft}>
          <img className={styles.phoneNewsImg} src={cover} alt="" />
        </div>
        <div
          className={styles.phoneNewsRight}
          style={{ width: `${window.innerWidth + 240 - 375 + "px"}` }}
        >
          <div className={styles.phoneNewsMainTitle}>{mainTitle}</div>
          <div className={styles.phoneNewsBottom}>
            <div className={styles.phoneNewsBottomDate}>{createTime}</div>
            <div className={styles.phoneNewsBottomLink}>{"详情 >"}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PhoneNewsCard;
