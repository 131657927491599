import React from "react";
import { Image } from 'antd';
import phoneTitle1 from '../../../../../assets/phoneTitle1@2x.png';
import phoneTitle2 from '../../../../../assets/phoneTitle2@2x.png';
import phoneTitle3 from '../../../../../assets/phoneTitle3@2x.png';
import org from '../../../../../assets/org.png';
import business from '../../../../../assets/business.png';
import operate from '../../../../../assets/operate.png';
import node1 from '../../../../../assets/2021.png';
import node2 from '../../../../../assets/2022.png';
import cert1 from '../../../../../assets/cert1.png';
import cert2 from '../../../../../assets/cert2.png';
import cert3 from '../../../../../assets/cert3.png';
import cert4 from '../../../../../assets/cert4.png';
import cert5 from '../../../../../assets/cert5.png';
import cert6 from '../../../../../assets/cert6.png';
import styles from "./index.module.less";

const Index = () => {
  return (
    <div
      className={styles.bodyAbout}
    >
      <div className={styles.top}>
        <div className={styles.topName}>
          <Image height={35} src={phoneTitle1} preview={false} />
        </div>
        <div className={styles.topBlock}>
          <div className={styles.topBlockTitle}>组织节点</div>
          <div className={styles.topBlockSubtitle}>宁波标准区块链产业发展研究院</div>
          <div className={styles.topBlockDesc}>负责政策宣导、监管对接、人才培养</div>
          <div className={styles.topBlockImage}>
            <Image
              src={org}
              preview={false}
            />
          </div>
        </div>
        <div className={styles.topBlock}>
          <div className={styles.topBlockTitle}>业务节点</div>
          <div className={styles.topBlockSubtitle}>覆盖30省市公信力机构</div>
          <div className={styles.topBlockDesc}>信证链业务的创新与落地</div>
          <div className={styles.topBlockImage}>
            <Image
              src={business}
              preview={false}
            />
          </div>
        </div>
        <div className={styles.topBlock}>
          <div className={styles.topBlockTitle}>运营节点</div>
          <div className={styles.topBlockSubtitle}>杭州链城数字科技有限公司</div>
          <div className={styles.topBlockDesc}>信证链的技术支撑与运营保障</div>
          <div className={styles.topBlockImage}>
            <Image
              src={operate}
              preview={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.middleName}>
          <Image height={35} src={phoneTitle2} preview={false} />
        </div>
        <div className={styles.middleNode}>
          <Image src={node2} preview={false} />
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「生态」</div>
            <div className={styles.cardTime}>目前</div>
            <div className={styles.cardContent}>依托于信证链的中国信证联盟链已覆盖全国30个省/市/自治区的30余家公证处节点，并与浙江市监司法鉴定中心、浙江省知识产权保护中心建立战略合作。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「服务」</div>
            <div className={styles.cardTime}>6月</div>
            <div className={styles.cardContent}>基于信证链的公证业务平台正式支持海外身份认证，将服务于更多的当事人群体。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「生态」</div>
            <div className={styles.cardTime}>3月</div>
            <div className={styles.cardContent}>信证联盟线上系列直播“信证说”正式开播，依托新媒体阵地为新型法律服务行业从业者、爱好者、关注者提供一个分享行业前沿观点、探讨行业发展方向、分享中国信证联盟最新动态的平台。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「拓展」</div>
            <div className={styles.cardTime}>3月</div>
            <div className={styles.cardContent}>“中国信证联盟数字藏品高峰论坛”成功举办，30余家公信力机构、律师事务所、协会、数字藏品从业者及爱好者等共百余人参会，论坛围绕数字藏品生态建设中的问题与对策、数字藏品法律合规性、信证联盟赋能数字藏品生态等主题展开了交流与探讨，标志着信证链将在数据资产化方向。</div>
          </div>
        </div>
        <div className={styles.middleNode}>
          <Image src={node1} preview={false} />
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「服务」</div>
            <div className={styles.cardTime}>12月</div>
            <div className={styles.cardContent}>基于信证链的全国首个异地协同公证业务圆满办理，进一步通过新技术推动公证服务能力的提升。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「生态」</div>
            <div className={styles.cardTime}>11月</div>
            <div className={styles.cardContent}>第一届“中国信证联盟日”论坛于宁波标准区块链产业发展研究院成功举办；来自20余个省市的公信力机构出席，一同围绕行业前沿动态、构建联盟生态圈、助推产业高质量发展等议题展开讨论，依托于信证链的联盟生态初步成型。</div>
            <div className={styles.cardKey}>「服务」</div>
            <div className={styles.cardTime}>11月</div>
            <div className={styles.cardContent}>基于信证链的远程视频公证服务正式上线，积极探索“公证办理不必到场”的创新业务形态。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「拓展」</div>
            <div className={styles.cardTime}>10月</div>
            <div className={styles.cardContent}>信证链正式签约知名餐饮品牌避风塘，帮助企业将事后取证向事前预防性取证转变，为品牌维权节约时间精力成本。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「资质」</div>
            <div className={styles.cardTime}>8月</div>
            <div className={styles.cardContent}>信证链顺利完成信息系统安全等级保护三级备案，具备了信息安全防护能力，能够防范信息安全风险，保障业务平台系统安全稳定、可靠运行。</div>
            <div className={styles.cardKey}>「上榜」</div>
            <div className={styles.cardTime}>8月</div>
            <div className={styles.cardContent}>入选宁波市经济和信息化局评选的“2021年度宁波市区块链示范项目”。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「上榜」</div>
            <div className={styles.cardTime}>5月</div>
            <div className={styles.cardContent}>入选国家级咨询项目《中国区块链发展战略研究》创新应用课题，并成为“发现100个中国区块链创新应用”发布的首个应用。</div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.prefix}></div>
          <div className={styles.link}></div>
          <div className={styles.card}>
            <div className={styles.cardKey}>「启动」</div>
            <div className={styles.cardTime}>4月28日</div>
            <div className={styles.cardContent}>于北京国家会议中心举办的第五届“区块链技术与应用高峰论坛”上全国首发。</div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomName}>
          <Image height={35} src={phoneTitle3} preview={false} />
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert1}
              preview={false}
              height={206}
            />
          </div>
          <div className={styles.bottomBlockDesc}>信息系统安全等级保护三级备案证明</div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert2}
              preview={false}
            />
          </div>
          <div className={styles.bottomBlockDesc}>中国工程院《中国区块链发展战略研究》创新应用课题“发现100个中国区块链创新应用”首个应用案例</div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert3}
              preview={false}
            />
          </div>
          <div className={styles.bottomBlockDesc}>中国信息协会区块链专业委员会（BCCIIA）副会长单位</div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert4}
              preview={false}
            />
          </div>
          <div className={styles.bottomBlockDesc}>2021政府信息产品技术创新奖</div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert5}
              preview={false}
            />
          </div>
          <div className={styles.bottomBlockDesc}>在线数据处理与交易处理业务经营许可证</div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.bottomBlockImage}>
            <Image
              src={cert6}
              preview={false}
            />
          </div>
          <div className={styles.bottomBlockDesc}>2021全球区块链企业创新50强</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
