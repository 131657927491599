import React, { useCallback } from "react";
import { message, Upload, Form } from "antd";
import { FolderOpenOutlined } from "@ant-design/icons";
import styles from "./index.module.less";

const { Dragger } = Upload;

const Index = (props) => {
  const { onSubmit } = props;
  const [form] = Form.useForm();

  const handleFileSubmit = (file) => {
    if (file) {
      onSubmit(file);
      return;
    }
    message.error("请上传存证文件");
  };

  const normFile = useCallback((e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.formWrap}>
        <Form
          name="form"
          form={form}
          requiredMark={false}
          className={styles.form}
        >
          <Form.Item
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Dragger
              maxCount={1}
              multiple={false}
              beforeUpload={(file, fileList) => {
                return new Promise((resolve, reject) => {
                  if (file.name.slice(-4) === ".exe") {
                    message.error("不支持类型的文件");
                    return;
                  }
                  const isLt200M = file.size / 1024 / 1024 < 200;
                  if (!isLt200M) {
                    return message.error("文件大小不能超过200MB!");
                  }
                  if (isLt200M) {
                    resolve();
                  }
                  resolve();
                });
              }}
              customRequest={async ({ file, filename, onSuccess, onError }) => {
                const isLt200M = file.size / 1024 / 1024 < 200;
                if (!isLt200M) {
                  message.error("文件大小不能超过200MB!");
                }

                if (isLt200M) {
                  const checkFile = {
                    file,
                    name: form.getFieldValue("file")[0]?.name,
                  };
                  handleFileSubmit(checkFile);
                  onSuccess();
                } else {
                  onError();
                }
              }}
              onRemove={async () => {
                return true;
              }}
            >
              <div className={styles.uploadIcon}>
                <FolderOpenOutlined />
              </div>
              <p className={styles.text}>点击或拖拽上传文件</p>
              <p className={styles.uploadTips}>
                请上传文件，文件大小应不超过200M
              </p>
            </Dragger>
          </Form.Item>
          
        </Form>
      </div>
    </div>
  );
};
export default Index;
