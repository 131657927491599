import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import Header from "../../components/NewComponents/Header";
import NFTCert from "../../components/NewComponents/NFTCert";
import SideButton from "../../components/NewComponents/SideButton";
import Footer from "../../components/NewComponents/Footer";

export default function NFTCertPage() {
  const location = useLocation();

  const [isOutterLink, setIsOutterLink] = useState(false);

  useEffect(() => {
    if (location.search.indexOf('from') > 0) {
      setIsOutterLink(true);
    }
  }, [location]);

  return (
    <>
      <Header selectedKeys={['nftcert']} />
      <NFTCert />
      <SideButton label="联系我们" url="/contact" />
      <Footer isOutterLink={isOutterLink} />
    </>
  )
}