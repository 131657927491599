import styles from "./index.module.less";

export default function NewsCard(props) {
  const { url, key, cover, mainTitle, subtitle, createTime } = props;

  return (
    <div className={styles.news} key={key} onClick={() => window.open(url)}>
      <div className={styles.newsLeft}>
        <img className={styles.newsImg} src={cover} alt="" />
      </div>
      <div className={styles.newsRight}>
        <div className={styles.newsTitle}>{mainTitle}</div>
        <div className={styles.newsSubTitle}>{subtitle}</div>
        <div className={styles.newsDate}>{createTime}</div>
      </div>
    </div>
  );
}
