import { Image } from "antd";
import classNames from "classnames";
import dataLineImg from '../../../../../assets/assetCopyright/dataLine.png'
import copyrightLineImg from '../../../../../assets/assetCopyright/copyrightLine.png'
import styles from "./index.module.less";

export default function Index() {
  const content = [{
    title: styles.titleData,
    content: dataLineImg
  }, {
    title: styles.titleCopyRight,
    content: copyrightLineImg
  }];

  return (
    <>
      {content.map((item, index) =>
        <div key={index} className={styles.sec} >
          <div className={classNames(item.title, styles.title)} />
          <Image src={item.content} preview={false} />
        </div>
      )}
    </>
  );
}