import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Image, Row, Col } from "antd";
import styles from "./index.module.less";
import aboutTopImgMobile from "../../../assets/newImg/aboutImg/aboutTopImgMobile@2x.png";
import title1 from '../../../assets/title1@2x.png';
import title3 from '../../../assets/title3@2x.png';
import org from '../../../assets/org.png';
import business from '../../../assets/business.png';
import operate from '../../../assets/operate.png';
import timeline from '../../../assets/timeline@2x.png';
import cert1 from '../../../assets/cert1.png';
import cert2 from '../../../assets/cert2.png';
import cert3 from '../../../assets/cert3.png';
import cert4 from '../../../assets/cert4.png';
import cert5 from '../../../assets/cert5.png';
import cert6 from '../../../assets/cert6.png';
import About from "./components/about";

const Index = () => {
  const location = useLocation();

  useEffect(() => {
    // 当路由切换时 滚动到页面顶部
    window.scrollTo(0, 0);
  }, [location]);

  const pc = (
    <>
      <div className={styles.topBg}>
      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <div className={styles.topName}>
            <Image
              height={40}
              src={title1}
              preview={false}
            />
          </div>
          <Row justify='space-between'>
            <Col span={8}>
              <div className={styles.topBlock}>
                <div>
                  <Image
                    src={org}
                    preview={false}
                  />
                </div>
                <div className={styles.topBlockTitle}>组织节点</div>
                <div className={styles.topBlockSubtitle}>宁波标准区块链产业发展研究院</div>
                <div className={styles.topBlockDesc}>负责政策宣导、监管对接、人才培养</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.topBlock}>
                <div>
                  <Image
                    src={business}
                    preview={false}
                  />
                </div>
                <div className={styles.topBlockTitle}>业务节点</div>
                <div className={styles.topBlockSubtitle}>覆盖30省市公信力机构</div>
                <div className={styles.topBlockDesc}>信证链业务的创新与落地</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.topBlock}>
                <div>
                  <Image
                    src={operate}
                    preview={false}
                  />
                </div>
                <div className={styles.topBlockTitle}>运营节点</div>
                <div className={styles.topBlockSubtitle}>杭州链城数字科技有限公司</div>
                <div className={styles.topBlockDesc}>信证链的技术支撑与运营保障</div>
              </div>
            </Col>
          </Row>
        </div>

        <div className={styles.middle}>
          <div className={styles.middleBlock}>
            <Image
              src={timeline}
              preview={false}
            />
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottomName}>
            <Image
              height={40}
              src={title3}
              preview={false}
            />
          </div>
          <Row justify='space-between' gutter={[48, 24]}>
            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert1}
                    preview={false}
                    height={206}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>信息系统安全等级保护三级备案证明</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert2}
                    preview={false}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>中国工程院《中国区块链发展战略研究》创新应用课题“发现100个中国区块链创新应用”首个应用案例</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert3}
                    preview={false}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>中国信息协会区块链专业委员会（BCCIIA）副会长单位</div>
              </div>
            </Col>

            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert4}
                    preview={false}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>2021政府信息产品技术创新奖</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert5}
                    preview={false}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>在线数据处理与交易处理业务经营许可证</div>
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.bottomBlock}>
                <div className={styles.bottomBlockImage}>
                  <Image
                    src={cert6}
                    preview={false}
                  />
                </div>
                <div className={styles.bottomBlockDesc}>2021全球区块链企业创新50强</div>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    </>
  );

  const mobile = (
    <>
      <div className={styles.aboutPhone}>
        <div className={styles.aboutPhoneTopImgContainer}>
          <img
            className={styles.aboutPhoneTopImg}
            src={aboutTopImgMobile}
            width={window.innerWidth}
            alt=""
          />
        </div>
      </div>
      <div className={styles.aboutPhoneContent}>
        <About />
      </div>
    </>
  );

  return (
    <>
      {pc}
      {mobile}
    </>
  );
};

export default Index;
